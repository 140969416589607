.search-filter-form {
    .well;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;

    &__heading {
        margin-top: 0;
    }

    &__label {
        font-weight: 400;
        font-size: @font-size-small;
    }

    &__input {
        .input-sm;
    }

    &__input-group-addon {
        .input-sm;
        background: none;
        border: 0;
    }

    &__submit {
        .btn-primary;
        .btn-block;

        @media (max-width: @screen-md-max) {
            .btn-sm;
        }
    }
}
// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@slidebar-position:                 right; // right tai left
@slidebar-width:                    300px;
@slidebar-nav-top:                  50px; // kuinka kaukaa yläreunasta navigaatio alkaa

@slidebar-bg:                       @gray-darker;
@slidebar-color:                    #fff;
@slidebar-link-bg:                  transparent;
@slidebar-link-color:               #fff;
@slidebar-link-border-color:        @gray-dark;
@slidebar-link-font-size:           @font-size-base;
@slidebar-link-font-weight:         @font-weight-semibold;
@slidebar-link-hover-bg:            @gray-dark;
@slidebar-link-hover-color:         @slidebar-link-color;
@slidebar-link-padding-x:           20px;
@slidebar-link-padding-y:           10px;

@slidebar-close-link-size:          40px;
@slidebar-close-link-bg:            transparent;
@slidebar-close-link-color:         @slidebar-link-color;
@slidebar-close-link-border-color:  transparent;
@slidebar-close-link-border-width:  0;
@slidebar-close-link-hover-bg:      @slidebar-link-hover-bg;
@slidebar-close-link-hover-color:   @slidebar-link-hover-color;
@slidebar-close-link-hover-border-color: @slidebar-close-link-border-color;

@slidebar-toggle-size:              60px;
@slidebar-toggle-bg:                transparent;
@slidebar-toggle-color:             #fff;
@slidebar-toggle-border-color:      transparent;
@slidebar-toggle-border-width:      0;
@slidebar-toggle-hover-bg:          transparent;
@slidebar-toggle-hover-color:       @slidebar-toggle-color;
@slidebar-toggle-hover-border-color: transparent;
@slidebar-toggle-text-shadow:       1px 1px 2px rgba(0, 0, 0, 0.6);


.slidebar-toggle {

    .square(@slidebar-toggle-size);
    background-color: @slidebar-toggle-bg;
    border: @slidebar-toggle-border-width solid @slidebar-toggle-border-color;
    color: @slidebar-toggle-color;
    line-height: @slidebar-toggle-size;
    text-align: center;
    position: fixed;
    top: 0;
    z-index: 1;
    text-shadow: @slidebar-toggle-text-shadow;

    & when (@slidebar-position = right) {
        right: 0;
    }

    & when (@slidebar-position = left) {
        left: 0;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: @slidebar-toggle-hover-bg;
        border-color: @slidebar-toggle-hover-border-color;
        color: @slidebar-toggle-hover-color;
    }

    .fa {
        font-size: @slidebar-toggle-size/2;
        line-height: @slidebar-toggle-size - (2*@slidebar-toggle-border-width);
    }

}

.slidebar {

    background: @slidebar-bg;
    position: fixed;
    width: @slidebar-width;
    height: 100%; 
    overflow-y: auto;
    transition: all 0.4s ease 0s;
    z-index: 1000;

    & when (@slidebar-position = right) {
        right: 0;
        transform: translateX(@slidebar-width);
    }

    & when (@slidebar-position = left) {
        left: 0;
        transform: translateX(-@slidebar-width);
    }

    &.active {

        width: @slidebar-width;
        transition: all 0.4s ease 0s;

        & when (@slidebar-position = right) {
            right: @slidebar-width;
        }

        & when (@slidebar-position = left) {
            left: @slidebar-width;
        }

    }

    &__close {

        .square(@slidebar-close-link-size);
        background-color: @slidebar-close-link-bg;
        border: @slidebar-close-link-border-width solid @slidebar-close-link-border-color;
        color: @slidebar-close-link-color;
        float: right;
        line-height: @slidebar-close-link-size;
        position: relative;
        right: 0;
        top: 0;
        text-align: center;

        & when (@slidebar-position = left) {
            float: left;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: @slidebar-close-link-hover-bg;
            border-color: @slidebar-close-link-hover-border-color;
            color: @slidebar-close-link-hover-color;
        }

        .fa {
            font-size: @slidebar-close-link-size/2;
            line-height: @slidebar-close-link-size - (2*@slidebar-close-link-border-width);
        }

    }

    &__nav {

        position: absolute;
        top: @slidebar-nav-top;
        width: @slidebar-width;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {

            > a {
                background-color: @slidebar-link-bg;
                border-bottom: 1px solid @slidebar-link-border-color;
                color: @slidebar-link-color;
                display: block;
                font-size: @slidebar-link-font-size;
                font-weight: @slidebar-link-font-weight;
                line-height: 1.0;
                padding: @slidebar-link-padding-y @slidebar-link-padding-x;

                &:hover,
                &:active,
                &:focus {
                    background-color: @slidebar-link-hover-bg;
                    color: @slidebar-link-hover-color;
                    text-decoration: none;
                }
            }

            &:first-child {
                border-top: 1px solid @slidebar-link-border-color;
            }
        }

    }

}
// GRID
// ----
// 
// Gridin CSS-luokkia voi käyttää suoraankin, mutta suositeltu tapa on LESS-extend.
// Esimerkiksi tuotelistan tyylit voisi määritellä sivustokohtaisissa tyyleissä näin:
// .product-list:extend(.grid all) {}
// .product-list__item:extend(.grid__item all, .grid__item--4-cols all) {}
// 
// LESS ei osaa extendata tyylejä, jotka on nestattu tyyliin ".grid { &__item }".
// Siksi tässä tiedostossa on paljon toistoa.

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@font-weight-semibold:          600;
@transition-duration-default:   0.4s;
@transition-duration-hover:     0.2s;
@grid-image-size:               180px;

// Grid: Perusrakenne
// ------------------

.grid {

	.make-row();

	// Siltä varalta, että .grid on listaelementti:
	.list-unstyled;
	padding: 0;

}

.grid__item {
	margin-bottom: @grid-gutter-width;
	text-align: center;
}

// Alla on määritelty eri levyisiä .grid__item-elementtejä eri palstamäärille.
// Esim. .grid__item--4-cols on leveimmässä koossaan 4-palstaiselle gridille.
// Palstamäärät eri näyttöko’oille ovat oletuksia; jyrää tarvittaessa.

// 3 4 6 6
.grid__item--6-cols {
	.make-xs-column(4);
	.make-sm-column(3);
	.make-md-column(2);

	&:nth-child(3n+1) { @media (max-width: @screen-xs-max) { clear: both; } }
	&:nth-child(4n+1) { @media (min-width: @screen-sm) and (max-width: @screen-sm-max) { clear: both; } }
	&:nth-child(6n+1) { @media (min-width: @screen-md) { clear: both; } }
}

// 2 3 4 4
.grid__item--4-cols {
	.make-xs-column(6);
	.make-sm-column(4);
	.make-md-column(3);

	&:nth-child(2n+1) { @media (max-width: @screen-xs-max) { clear: both; } }
	&:nth-child(3n+1) { @media (min-width: @screen-sm) and (max-width: @screen-sm-max) { clear: both; } }
	&:nth-child(4n+1) { @media (min-width: @screen-md) { clear: both; } }
}

// 2 3 3 3
.grid__item--3-cols {
	.make-xs-column(12);
	.make-ms-column(6);
	.make-sm-column(4);
	.make-md-column(4);

	&:nth-child(2n+1) { @media (max-width: @screen-ms-max) { clear: both; } }
	&:nth-child(3n+1) { @media (min-width: @screen-sm) and (max-width: @screen-sm-max) { clear: both; } }
	&:nth-child(3n+1) { @media (min-width: @screen-md) { clear: both; } }
}

// 1 2 2 2
.grid__item--2-cols {
	.make-xs-column(12);
	.make-sm-column(6);
	.make-md-column(6);

	&:nth-child(2n+1) { @media (min-width: @screen-sm) and (max-width: @screen-sm-max) { clear: both; } }
	&:nth-child(2n+1) { @media (min-width: @screen-md) { clear: both; } }
}

// 1 1 1 1
.grid__item--1-cols {
	.make-xs-column(12);
}


// Grid: Oletustyylejä
// -------------------

// Rivit erotetaan toisistaan ohuella borderilla, sopii hyvin esim. perustuotelistaan
.grid__item--bordered {
	border-bottom: 1px solid @gray-lighter;
	padding-bottom: @grid-gutter-width;
}

// Jos linkki ympäröi koko grid__itemiä, sen on oltava blokki
.grid__link-wrapper {
	display: block;
	position: relative;
}

.grid__link-wrapper:hover,
.grid__link-wrapper:focus {
	text-decoration: none;
}

.grid__link-wrapper--fixed-height {
	height: @grid-image-size;
}

.grid__link-wrapper--well {
	background: @gray-lighter;
	padding: @grid-gutter-width/2;
}

.grid__link-wrapper--background {
	background-size: cover;
	background-position: center center;
}

.grid__link-wrapper--hover-block {
	padding: @grid-gutter-width/2;
}

.grid__link-wrapper--hover-block:hover {
	background: @gray-lighter;
}

.grid__link-wrapper--hover-enlarge:hover {
	transform: scale(1.1);
}

// Grid: Otsikko
// -------------

.grid__heading {
	font-size: @font-size-large;
	font-weight: @font-weight-semibold;
}

// Otsikko ylhäällä
.grid__heading--top {
	margin: 0 0 @grid-gutter-width/2 0;
}

// Otsikko alhaalla
.grid__heading--bottom {
	margin: @grid-gutter-width/2 0 0 0;
}

// Otsikko (taustavärin kera) itemin alareunassa
.grid__heading--cover-block {
	background: @gray-darker;
	color: #fff;
	margin: 0;
	padding: @grid-gutter-width/2;
	position: absolute;
	bottom: 0;
	width: 100%;
}

// Otsikko gridin sisällä muun sisällön päällä
.grid__heading--cover {
	color: #fff;
	font-size: @font-size-h2;
	position: absolute;
	top: 0;
	width: 100%;
	margin: 0;
	padding: @grid-gutter-width/2;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.6);
	z-index: 1;
}

// Otsikko näkyy vain hoverina
.grid__heading--hover-show {
	opacity: 0;
	.transition(@transition-duration-default);
}

a:hover .grid__heading--hover-show {
	opacity: 1;
	.transition(@transition-duration-hover);
}

// Otsikko piilotetaan hoverina
.grid__heading--hover-hide {
	opacity: 1;
	.transition(@transition-duration-default);
}

a:hover .grid__heading--hover-hide {
	opacity: 0;
	.transition(@transition-duration-hover);
}

// Grid: Kuva
// ----------

.grid__image-container {
	margin: 0 auto @grid-gutter-width/2 auto;
	max-height: 100%;
	max-width: 100%;
}

.grid__image-container--square {
	height: @grid-image-size;
	width: @grid-image-size;
}

.grid__image {
	.img-responsive;
}

.grid__image--square {
	max-height: 98%;
	max-width: 98%;
}

// Kuva kasvaa hoverina
.grid__image--hover-enlarge {
	.transition(@transition-duration-default);
}

a:hover .grid__image--hover-enlarge {
	.transition(@transition-duration-hover);
	transform: scale(1.2);
}

// Kuva mustavalkoiseksi hoverina
.grid__image--hover-bw {
	.transition(@transition-duration-default);
}

a:hover .grid__image--hover-bw {
	.transition(@transition-duration-hover);
	filter: grayscale(100%);
}

// Kuva värilliseksi hoverina (muuten mv)
.grid__image--hover-color {
	.transition(@transition-duration-default);
	filter: grayscale(100%);
}

a:hover .grid__image--hover-color {
	.transition(@transition-duration-hover);
	filter: grayscale(0%);
}


// Grid: Kuvaus
// ------------

.grid__description {
	color: @text-color;
	font-size: @font-size-small;
	line-height: 1.2;
	margin-top: @grid-gutter-width/2;
	text-align: left;
}

// Kuvaus gridin sisällä muun sisällön päällä
.grid__description--cover {
	position: absolute;
	bottom: 0;
	background: rgba(0,0,0,0.7);
	color: #fff;
	padding: @grid-gutter-width/2;
	width: 100%;
}

// Kuvaus näkyy vain hoverina
.grid__description--hover-show {
	opacity: 0;
	transition-duration: @transition-duration-default;
}

a:hover .grid__description--hover-show {
	opacity: 1;
	transition-duation: @transition-duration-hover;
}


// Grid: Linkki
// ------------

.grid__link {
	margin-top: @grid-gutter-width/2;
}

.grid__link--button {
	.btn;
	.btn-default;
}

.grid__link--button-block {
	.btn-block;
}
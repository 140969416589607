// Nostot
// ------

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
// (Kaikkia ei vaikuteta käytettävän, tarkistetaan tämä jossain vaiheessa)
@featured-item-bg:                      @gray-lighter;
@featured-item-color:                   @text-color;
@featured-item-border:                  transparent;
@featured-item-padding:                 10px;
@featured-item-heading-color:           @headings-color;
@featured-item-heading-font-weight:     @headings-font-weight;

.flexible-featured-list {

    &:extend(.grid all);
    
    &:before,
    &:after {
        content: normal; // Korjaa Safarin flexbox-ongelman
    }

    display: flex;
    flex-wrap: wrap;
    margin-top: @grid-gutter-width;

    &--2-col {
        @media (min-width: @screen-lg) {
            padding-left: @grid-gutter-width*2.5;
            padding-right: @grid-gutter-width*2.5;
        }
    }

    &__item {
        border: 0;
        display: flex;
        padding-bottom: 0;
        margin-bottom: @grid-gutter-width/2;
        text-align: left;

        .flexible-featured-list--4-col & {
            .make-xs-column(12);
            .make-ms-column(8);
            .make-ms-column-offset(2);
            .make-sm-column(6);
            .make-sm-column-offset(0);
            .make-md-column(6);
            .make-lg-column(6);

            // Clearaukset näytön leveyden mukaan (2-4 palstaa)
            &:nth-child(2n+1) {
                // @media (max-width: @screen-lg-max) {
                    clear: both;
                // }
            }

            &:nth-child(3n+1) {
                @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
                    clear: none;
                }
            }

            &:nth-child(4n+1) {
                @media (min-width: @screen-md) {
                    clear: none;
                }
            }
        }

        .flexible-featured-list--3-col & {
            .make-xs-column(12);
            .make-ms-column(8);
            .make-ms-column-offset(2);
            .make-sm-column(4);
            .make-sm-column-offset(0);
            .make-md-column(4);

            // Clearaukset näytön leveyden mukaan (2-4 palstaa)
            &:nth-child(2n+1) {
                @media (max-width: @screen-xs-max) {
                    clear: both;
                }
            }

            &:nth-child(3n+1) {
                @media (min-width: @screen-sm) {
                    clear: both;
                }
            }

            &:nth-child(4n+1) {
                @media (min-width: @screen-md) {
                    clear: none;
                }
            }
        }

        .flexible-featured-list--2-col & {
            .make-xs-column(12);
            .make-ms-column(8);
            .make-ms-column-offset(2);
            .make-sm-column(6);
            .make-sm-column-offset(0);
            .make-md-column(6);
        }

        .flexible-featured-list--1-col & {
            .make-xs-column(12);
            .make-ms-column(8);
            .make-ms-column-offset(2);
            .make-sm-column(6);
            .make-sm-column-offset(3);
        }
    }

    &__thumbnail {
        background-color: @featured-item-bg;
        padding-bottom: @grid-gutter-width;
        width: 100%;
    }

    &__heading {
        color: @featured-item-heading-color;
        font-size: @font-size-h3;
        font-weight: @featured-item-heading-font-weight;
        margin-top: 0;
        margin-bottom: @grid-gutter-width/3;
        height: auto;
        text-align: left;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__image {
        width: 100%;

        .flexible-featured-list--1-col & {
            @media (min-width: @screen-sm) {
                margin-bottom: 0;
            }
        }
    }

    &__text-content {
        color: @featured-item-color;
        padding: @grid-gutter-width/2;

        &--empty {
            padding: 0;
        }
    }

    &__description {
        background: none;
        font-size: @font-size-small;
        display: block;
        margin-bottom: @grid-gutter-width/3;
        padding: 0;
        position: static;
        text-align: left;

        &:last-child {
            margin-bottom: 0;
        }

        .flexible-featured-list--2-col &,
        .flexible-featured-list--1-col & {
            @media (min-width: @screen-sm) {
                font-size: @font-size-base;
            }
        }
    }

    &__read-more-link {
        .btn;
        .btn-block;
        .btn-default;
        position: absolute;
        left: @grid-gutter-width/2;
        bottom: 0;
        width: calc(~"100% - "@grid-gutter-width);
        text-align: center;

        .flexible-featured-list--1-col & {
            @media (min-width: @screen-sm) {
                padding: @grid-gutter-width/3 @grid-gutter-width/2;
            }
        }
    }

}
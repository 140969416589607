.cart-tax-breakdown {
    margin-top: @grid-gutter-width;

    @media (min-width: @screen-sm) {
        margin-top: 0;
    }

    th, td {
        border: 0 !important;
        padding-top: 2px !important;
        padding-bottom: 2px !important;

        &:first-child {
            padding-left: 0;
        }
    }
}
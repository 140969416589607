@cookie-notification-bg:                        @gray-lighter;
@cookie-notification-color:                     @gray-dark;
@cookie-notification-font-size:                 @font-size-small - 1;
@cookie-notification-link-color:                @gray-darker;
@cookie-notification-link-hover-color:          #000;
@cookie-notification-link-decoration:           underline;
@cookie-notification-link-hover-decoration:     none;
@cookie-notification-close-link-color:          @cookie-notification-link-color;
@cookie-notification-close-link-hover-color:    @cookie-notification-link-hover-color;

.cookie-notification {
    background-color: @cookie-notification-bg;
    color: @cookie-notification-color;
    font-size: @cookie-notification-font-size;
    padding: 8px 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.95;

    &__text {
        padding-right: 30px;
    }

    &__read-more {
        color: @cookie-notification-link-color;
        text-decoration: @cookie-notification-link-decoration;

        &:hover,
        &:active,
        &:focus {
            color: @cookie-notification-link-hover-color;
            text-decoration: @cookie-notification-link-hover-decoration;
        }
    }

    &__close-link {
        background-color: transparent;
        color: @cookie-notification-close-link-color;
        position: absolute;
        right: 3px;
        top: 3px;

        &:hover,
        &:active,
        &:focus {
            color: @cookie-notification-close-link-hover-color;
        }
    }
}
// Edustaa uutissivun isoa uutislistaa

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@news-list-mobile-max-width:            450px;
@news-list-item-margin-bottom:          30px;
@news-list-item-padding-bottom:         30px;
@news-list-item-border-bottom-color:    @gray-lighter;
@news-list-item-border-bottom-width:    1px;
@news-list-image-border-color:          @gray-lighter;
@news-list-image-border-width:          1px;
@news-list-heading-font-weight:         @headings-font-weight;
@news-list-heading-margin-bottom:       3px;
@news-list-date-color:                  @text-muted;
@news-list-date-font-weight:            700;
@news-list-read-more-link-display:      block;
@news-list-read-more-link-font-size:    inherit;
@news-list-read-more-link-font-weight:  @font-weight-semibold;
@news-list-read-more-link-margin-top:   5px;
@news-list-read-more-link-icon:         "\f105";
@news-list-read-more-link-icon-margin:  5px;

.news-list {
    max-width: @news-list-mobile-max-width;

    @media (min-width: @screen-sm-min) {
        max-width: none;
    }

    &__item {
        border-bottom: @news-list-item-border-bottom-width solid @news-list-item-border-bottom-color;
        margin-bottom: @news-list-item-margin-bottom;
        padding-bottom: @news-list-item-padding-bottom;

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    img {
        border: @news-list-image-border-width solid @news-list-image-border-color;
    }

    &__heading {
        font-weight: @news-list-heading-font-weight;
        margin-top: 0;
        margin-bottom: @news-list-heading-margin-bottom;
    }

    &__date {
        color: @news-list-date-color;
        font-weight: @news-list-date-font-weight;
    }

    &__text {
        @media (min-width: @screen-sm-min) {
            margin-bottom: 0;
        }
    }

    &__read-more-link {
        display: @news-list-read-more-link-display;
        font-size: @news-list-read-more-link-font-size;
        font-weight: @news-list-read-more-link-font-weight;
        margin-top: @news-list-read-more-link-margin-top;
        white-space: nowrap;

        &:after when not (@news-list-read-more-link-icon = "") {
            .fa();
            content: @news-list-read-more-link-icon;
            margin-left: @news-list-read-more-link-icon-margin;
        }
    }
}
// Hintaliukurien CSS
// Sisältää myös rangeslider.js:n tyylien jyräyksiä
// Täällä määritellään vain moduulin "sisäiset" perustyylit

.price-slider {

	margin-bottom: @grid-gutter-width;

	&__heading {}

	&__range-wrapper {
		padding-top: 10px;
	}

	&__output-wrapper {
		.make-xs-column(6);
		font-weight: @font-weight-semibold;
		margin-bottom: 0;
	}

	&__output {
		display: inline-block;
		padding: 0;
	}

	&__info-link-wrapper {
		.make-xs-column(6);
		text-align: right;
	}

	&__info-link {
		font-size: @font-size-small;
	}

	&__help-text {
		font-size: @font-size-small;
		text-align: left;
		
		@media (min-width: @screen-sm-min) {
			text-align: right;
		}
	}

}

// rangeslider.js
// --------------

.rangeslider,
.rangeslider__fill {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) inset;

	@media (min-width: @screen-sm) {
		height: 15px;
	}
}

.rangeslider {
	margin-bottom: 10px;
}

// "Väritetty" osuus
.rangeslider__fill {
	background: @brand-primary;
}

// Kahvan ulompi ympyrä
.rangeslider__handle {
	background: #eee;
	border: 0;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

	@media (min-width: @screen-sm) {

		// Tarvitaan ylimääräinen class, jotta jyrää rangesliderin perustyylit
		.rangeslider--horizontal & {
			height: 30px;
			width: 30px;
			top: -8px;
		}
	}

	// Kahvan sisempi ympyrä
	&::after {
		background: #bbb;
		height: 24px;
		width: 24px;

		@media (min-width: @screen-sm-min) {
			height: 18px;
			width: 18px;
		}
	}

	// Kahvan värit raahatessa
	&:active {
		background: #ddd;
		box-shadow: 1px 1px rgba(0, 0, 0, 0.4);

		&::after {
			background: #aaa;
		}
	}
}
//! FOOTER
// -------

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa

@footer-columns:                4; // Vaihtoehtoina 2 (Boom-versio) tai 4 ("normaali")
@footer-bg:                     @gray-dark;
@footer-color:                  @gray-light;
@footer-link-color:             #fff;
@footer-link-hover-color:       @gray-lighter;
@footer-heading-color:          #fff;
@footer-heading-font-size:      @font-size-h3;
@footer-heading-font-weight:    @headings-font-weight;
@footer-sitemap-font-size:      @font-size-small;
@footer-some-link-color:        @footer-link-color;
@footer-some-link-hover-color:  @brand-primary;
@footer-copyright-bg:           @gray-darker;
@footer-copyright-color:        @gray-light;
@footer-copyright-font-size:    @font-size-small;

.site-footer {
    background-color: @footer-bg;
    clear: both;
    color: @footer-color;
    padding: @grid-gutter-width*2 0 0 0;

    a {
        color: @footer-link-color;

        &:hover,
        &:active,
        &:focus {
            color: @footer-link-hover-color;
            text-decoration: none;
        }
    }

    &__heading {
        color: @footer-heading-color;
        font-size: @footer-heading-font-size;
        font-weight: @footer-heading-font-weight;
        margin-top: 0;
    }

    &__copyright-info {
        background: @footer-copyright-bg;
        color: @footer-copyright-color;
        font-size: @footer-copyright-font-size;
        margin-top: @grid-gutter-width*2;
        padding: @grid-gutter-width/2 0;
        text-align: center;
    }


    /* Footerista on kaksi pääversiota:
     * - Nelipalstainen: Sivukartta | Yhteystiedot | Maksutavat | Seuraa meitä
     * - ”Valmistajaversio”: Yhteystiedot + maksutavat | Valmistajat
     */

    // Footer-variantti 1: Sivukartta | Yhteystiedot | Maksutavat | Seuraa meitä
    // -------------------------------------------------------------------------

    // 1. palsta
    // ---------

    &__site-map when (@footer-columns = 4) {
        .make-sm-column(6);
        .make-md-column(3);

        font-size: @footer-sitemap-font-size;
        margin-bottom: @grid-gutter-width;
        text-align: center;

        @media (min-width: @screen-sm) {
            text-align: left;
        }

        @media (min-width: @screen-md) {
            margin-bottom: 0;
        }
    }

    // 2. palsta
    // ---------

    &__contact-details when (@footer-columns = 4) {
        .make-sm-column(6);
        .make-md-column(3);

        margin-bottom: @grid-gutter-width;
        text-align: center;

        @media (min-width: @screen-sm) {
            text-align: left;
        }

        @media (min-width: @screen-md) {
            margin-bottom: 0;
        }
    }

    // 3. palsta
    // ---------

    &__payment-info when (@footer-columns = 4) {
        .make-sm-column(6);
        .make-md-column(3);

        margin-bottom: @grid-gutter-width;
        text-align: center;

        @media (min-width: @screen-sm) {
            text-align: left;
        }

        @media (min-width: @screen-md) {
            margin-bottom: 0;
        }
    }

    // 4. palsta
    // ---------

    &__some when (@footer-columns = 4) {
        .make-sm-column(6);
        .make-md-column(3);

        text-align: center;

        @media (min-width: @screen-sm) {
            text-align: left;
        }
    }

    &__some-links when (@footer-columns = 4) {
        list-style: none;
        padding: 0;

        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: @grid-gutter-width/3;
            }
        }
    }

    &__some-link when (@footer-columns = 4) {

        .fa {
            color: @footer-some-link-color !important;
        }

        &:hover {
            .fa {
                color: @footer-some-link-hover-color !important;
            }
        }
    }


    // Footer-variantti 2: yhteystiedot, maksutavat + suuri valmistajalista
    // --------------------------------------------------------------------

    &__payment-info when (@footer-columns = 2) {
        .make-xs-column(10);
        .make-xs-column-offset(1);
        .make-sm-column(3);
        .make-sm-column-pull(9);
        .make-sm-column-offset(0);
        .make-lg-column(3);
        .make-lg-column-pull(9);

        margin-bottom: @grid-gutter-width;
        text-align: center;

        @media (min-width: @screen-sm) {
            text-align: left;
        }

        @media (min-width: @screen-md) {
            margin-bottom: 0;
        }
    }

    &__payment-info-image when (@footer-columns = 2) {
        background: #fff;
        margin-top: @grid-gutter-width;
        margin-bottom: @grid-gutter-width;
        padding: 4px;
        border-radius: 4px;
    }

    &__brands when (@footer-columns = 2) {
        .make-sm-column(9);
        .make-sm-column-push(3);
        .make-lg-column(9);
        .make-lg-column-push(3);

        @media (min-width: @screen-sm) {
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

}
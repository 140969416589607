// TUOTE-/KATEGORIALISTAUKSET
// --------------------------

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@product-list-heading-color:        @link-color;
@product-list-heading-font-size:    @font-size-base;
@product-list-heading-font-weight:  @font-weight-semibold;
@product-list-heading-height:       3.6em;
@product-list-heading-line-height:  @product-list-heading-height/3;
@product-list-price-font-size:      @font-size-base;
@product-list-price-font-weight:    400;
@product-list-price-color:          @text-color;

.product-list,
.category-list {
    &:extend(.grid all);

    &__item {
        &:extend(.grid__item all, .grid__item--4-cols all, .grid__item--bordered all);
    }

    &__link-wrapper {
        &:extend(.grid__link-wrapper all);
    }

    &__heading {
        &:extend(.grid__heading all, .grid__heading--bottom all);

        color: @product-list-heading-color;
        font-size: @product-list-heading-font-size;
        font-weight: @product-list-heading-font-weight;
        height: @product-list-heading-height;
        line-height: @product-list-heading-line-height;
        margin-bottom: 0;
        overflow: hidden;
    }

    &__image-container {
        &:extend(.grid__image-container all, .grid__image-container--square all);
    }

    &__image {
        &:extend(.grid__image all, .grid__image--square all);
    }

    &__description {
        &:extend(.grid__description all);
    }
}

.product-list {
    &__heading {
        margin-bottom: 5px;
    }

    &__price {
        color: @product-list-price-color;
        font-size: @product-list-price-font-size;
        font-weight: @product-list-price-font-weight;
    }

    // Ao. tyylit Rexelin € TAI p -systeemille
    .show-price {
        color: @product-list-price-color;
        font-size: @product-list-price-font-size;

        &__value {
            font-weight: @product-list-price-font-weight;
        }

        &__or {
            font-weight: 400;
            padding: 0 2px;
        }
    }
}
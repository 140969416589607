// Ostoskorin "yhteensä-osio"
// --------------------------

.cart-total {

	.make-row();
	font-size: @font-size-h4;
	margin-bottom: @grid-gutter-width;
	text-align: right;

	&__container {
		.make-sm-column(6);
		.make-sm-column-offset(6);
	}

	&__total {
		font-weight: 700;
		output {
			font-size: inherit;
		}
	}

	&__total-sum {
		
	}

	&__delivery-costs {
		border-bottom: 1px dashed @gray-lighter;
		margin-bottom: 5px;
		padding-bottom: 7px;
	}

	&__right {
		float: right;
		margin-left: @grid-gutter-width/3;
		text-align: right;
	}

	&__payment-line {
		font-size: @font-size-small;
		font-weight: 700;
		margin-bottom: 2px;
	}

}
.site-map {

    list-style: none;
    margin-bottom: 0;
    padding: 0;

    > li {
        margin: 0;

        > a {
            display: inline-block;
            padding: 3px 0;

            @media (min-width: @screen-sm) {
                padding: 1px 0;
            }
        }

        > ul {
            list-style: none;
            padding: 0;

            > li {
                &:before {
                    content: "»";
                    margin-right: 4px;
                }
            }
        }
    }

}
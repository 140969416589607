// Footerin mahdollinen valmistajalistaus

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@manufacturers-list-font-size:          @font-size-small - 1;
@manufacturers-list-link-color:         #fff;
@manufacturers-list-link-hover-color:   @gray-lighter;

.manufacturers-list {

    .list-unstyled;
    .row;

    font-size: @manufacturers-list-font-size;
    margin-bottom: @grid-gutter-width;
    padding-bottom: @grid-gutter-width/3;

    @media (min-width: @screen-sm) {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &__item {
        .make-xs-column(6);
        .make-sm-column(4);
        .make-md-column(3);
        
        // Viisi palstaa isoimmassa koossa:
        @media (min-width: @screen-lg) {
            width: 20%;
        }

        list-style: none;
        text-align: center;

        @media (min-width: @screen-sm) {
            text-align: left;
        }

        &:nth-child(2n+1) { @media (max-width: @screen-xs-max) { clear: both; } }
        &:nth-child(3n+1) { @media (min-width: @screen-sm) and (max-width: @screen-sm-max) { clear: both; } }
        &:nth-child(4n+1) { @media (min-width: @screen-md) and (max-width: @screen-md-max) { clear: both; } }
        &:nth-child(5n+1) { @media (min-width: @screen-lg) { clear: both; } }

    }

    &__link {
        color: @manufacturers-list-link-color !important;
        display: inline-block;
        padding: 2px 0;

        @media (min-width: @screen-sm-min) {
            padding: 0;
        }

        &:hover,
        &:active,
        &:focus {
            color: @manufacturers-list-link-hover-color !important;
        }
    }
}
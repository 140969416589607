//! +/- -nappulat (qty-buttons)
// ----------------------------

@qty-buttons-border:                        @gray-lighter;
@qty-buttons-max-width:                     110px;
@qty-buttons-input-bg:                      transparent;
@qty-buttons-input-color:                   @text-color;
@qty-buttons-input-font-weight:             @font-weight-semibold;
@qty-buttons-btn-bg:                        #fff;
@qty-buttons-btn-color:                     @text-color;
@qty-buttons-btn-hover-bg:                  @qty-buttons-btn-bg;
@qty-buttons-btn-hover-color:               @qty-buttons-btn-color;

.input-group.qty-buttons {

    border: 1px solid @qty-buttons-border;
    max-width: @qty-buttons-max-width;

    .table-products & {
        margin: 0 auto 3px auto;
    }

    .form-control {
        background-color: @qty-buttons-input-bg;
        border: 0;
        box-shadow: none;
        color: @qty-buttons-input-color;
        font-weight: @qty-buttons-input-font-weight;
    }

    .input-group-btn {
        .btn-default {
            background: @qty-buttons-btn-bg;
            border: 0;
            border-radius: 0;
            box-shadow: none;
            color: @qty-buttons-btn-color;

            &:hover {
                background: @qty-buttons-btn-hover-bg;
                color: @qty-buttons-btn-hover-color;
            }
        }
    }

}
.product-images {

    margin-bottom: @grid-gutter-width;

    @media (max-width: @screen-xs-max) {
        margin-bottom: 40px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &__main-images {
        .slick-prev,
        .slick-next {
            .square(30px);
        }

        .slick-prev {
            left: 20px;
        }

        .slick-next {
            right: 0px;
        }

        .slick-prev::before,
        .slick-next::before {
            color: @gray-darker;
            font-size: 30px;
        }
    }

    &__main-image {
        text-align: center;
    }

    &__main-link-wrapper {
        // Tämän elementin leveys on määritelty sivuston asetuksissa ja on inline-tyylinä
        cursor: zoom-in;
    }

    &__main-img-wrapper {
        margin-bottom: 20px;
    }

    &__main-img {
        margin-left: auto;
        margin-right: auto;
    }

    &__thumbnails {
        display: none;

        @media (min-width: @screen-sm-min) {
            display: block;
        }

        .slick-prev,
        .slick-next {
            .square(20px);
        }

        .slick-prev {
            left: -30px;

            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                left: -22px;
            }
        }

        .slick-next {
            right: -30px;

            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                right: -22px;
            }
        }

        .slick-prev::before,
        .slick-next::before {
            color: @gray-darker;
            font-size: 20px;
        }
    }

    &__thumbnail {
        display: inline-block;
    }

    &__thumbnail-img-wrapper {
        // Tämän elementin leveys on määritelty sivuston asetuksissa ja on inline-tyylinä
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }

    &__thumbnail-img {
        // Samaten itse kuvien koko tulee asetuksista ja on inline-tyylinä
        margin-left: auto;
        margin-right: auto;
        max-width: 100% !important;
        max-height: 100% !important;

        .slick-current & {
            border: 1px solid #ddd;
            opacity: 0.7;
        }
    }

    .slick-dots {
        bottom: -25px;
        margin-bottom: 0;

        li {
            height: 15px;
            width: 15px;

            button::before {
                font-size: 8px;
                opacity: 0.3;
            }
        }

        .slick-active button:before {
            color: @brand-primary;
            opacity: 0.6;
        }
    }

}
// .top-bar on yläpalkki varsinaisen navbarin yläpuolella.
// Tämä moduuli määrittelee vain sisältöalueen rakenteen.
// Ympärillä voi olla container, tai sitten ei.
// 
// Tiedostossa top-bar-links.less ovat listaelementteihin liittyvät tyylit

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@top-bar-bg:                    @gray;
@top-bar-color:                 @gray-light;
@top-bar-font-family:           inherit;
@top-bar-font-size:             @font-size-small;
@top-bar-font-weight:           400;
@top-bar-left-cols-xs:          7;
@top-bar-right-cols-xs:         5;
@top-bar-left-cols-ms:          6;
@top-bar-right-cols-ms:         6;

.top-bar {

    .make-row();

    background: @top-bar-bg;
    color: @top-bar-color;
    font-family: @top-bar-font-family;
    font-size: @top-bar-font-size;
    font-weight: @top-bar-font-weight;

    // Mahdollinen container
    &__block {
        background: @top-bar-bg;
    }

    &__link-area {

        &--general {
            .make-xs-column(@top-bar-left-cols-xs);
            .make-xs-column-pull(12 - @top-bar-left-cols-xs);
            .make-ms-column(@top-bar-left-cols-ms);
            .make-ms-column-pull(12 - @top-bar-left-cols-ms);
        }

        &--user {
            .make-xs-column(@top-bar-right-cols-xs);
            .make-xs-column-push(12 - @top-bar-right-cols-xs);
            .make-ms-column(@top-bar-right-cols-ms);
            .make-ms-column-push(12 - @top-bar-right-cols-ms);

            text-align: right;
        }

    }

}
@media print {

    @page {
        margin: 2cm;
    }

    body {
        background-color: #fff;
        color: #111;
    }

    .site-header,
    .site-footer,
    .sidebar,
    .breadcrumb {
        display: none;
    }

    .content {
        left: 0;
        margin-left: 0;
        width: 100%;
    }

    // Tuotekortti

    .product-images__thumbnails,
    .product-images__main-link-wrapper:after,
    .product__buttons {
        display: none;
    }

    .product__description-body a:link:after {
        content: " [" attr(href) "] ";
    }

    // Tilauksen vahvistus

    .order-pager {
        display: none;
    }

}
// Tilauksen osoitetiedot.
// Samaa elementtiä käytetään sekä julki- että admin-puolella.

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@order-contact-info-name-font-weight:   700;

.order-contact-info {
    &__name {
        font-weight: @order-contact-info-name-font-weight;
    }
}
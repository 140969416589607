@references-list-item-bg:                       #fff;
@references-list-item-max-width:                400px;
@references-list-item-box-shadow:               @card-box-shadow;
@references-list-item-box-shadow-hover:         @card-box-shadow-hover;
@references-list-heading-font-size:             @font-size-h3;
@references-list-heading-font-weight:           @headings-font-weight;
@references-list-heading-link-color:            @text-color;
@references-list-heading-link-hover-color:      @link-color;
@references-list-lead-color:                    @text-color;
@references-list-lead-margin-top:               10px;
@references-list-text-content-padding:          15px;

@reference-heading-font-size:                   @font-size-base * pow(@font-scale-factor, 6);
@reference-heading-font-weight:                 @headings-font-weight;
@reference-lead-font-size:                      @font-size-h3;
@reference-lead-font-weight:                    @font-weight-semibold;
@reference-lead-margin:                         @grid-gutter-width;

@reference-image-border-color:                  @gray-lighter;
@reference-image-box-shadow:                    @card-box-shadow;
@reference-image-box-shadow-hover:              @card-box-shadow-hover;
@reference-image-max-width:                     250px;

@reference-extra-fields-heading-font-size:      @font-size-h3;
@reference-extra-fields-heading-font-weight:    @font-weight-semibold;


.references-list {

    @media (max-width: @screen-ms-max) {
        margin-left: auto;
        margin-right: auto;
        max-width: @references-list-item-max-width;
    }

    // .col
    &__item {
        margin-bottom: @grid-gutter-width;

        @media (max-width: @screen-ms-max) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__wrapper {
        background-color: @references-list-item-bg;
        box-shadow: @references-list-item-box-shadow;
        max-width: @references-list-item-max-width;
        position: relative;
        transition: @transition-duration-default;

        &:hover {
            box-shadow: @references-list-item-box-shadow-hover;
            transition: @transition-duration-hover;
        }
    }

    &__link-wrapper {
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    &__heading {
        color: @references-list-heading-link-color;
        font-size: @references-list-heading-font-size;
        font-weight: @references-list-heading-font-weight;
        margin-top: 0;
        margin-bottom: 0;

        .references-list__item:hover &,
        .references-list__item:active &,
        .references-list__item:focus & {
            color: @references-list-heading-link-hover-color;
        }
    }

    &__lead {
        color: @references-list-lead-color;
        margin-top: @references-list-lead-margin-top;
        margin-bottom: 0;
    }

    &__text-content {
        padding: @references-list-text-content-padding;
    }

}

.reference {

    &__main-image {
        margin-top: -(@grid-gutter-width);
        margin-bottom: @grid-gutter-width/1.5;
    }

    &__main-image-img {
        width: 100% !important;
    }

    &__heading {
        font-size: @font-size-h1;
        font-weight: @reference-heading-font-weight;

        @media (min-width: @screen-sm-min) {
            font-size: @reference-heading-font-size;
        }
    }

    &__lead {
        font-weight: @reference-lead-font-weight;

        @media (min-width: @screen-sm-min) {
            font-size: @reference-lead-font-size;
        }
    }

}

.reference-images {
    margin-top: @grid-gutter-width;

    &__item {
        margin-bottom: @grid-gutter-width;
    }

    &__link-wrapper {
        cursor: zoom-in;
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: @reference-image-max-width;

        @media (min-width: @screen-sm-min) {
            max-width: none;
        }
    }

    &__img {
        border: 1px solid @reference-image-border-color;
        box-shadow: @reference-image-box-shadow;
        transition: @transition-duration-default;

        &:hover {
            box-shadow: @reference-image-box-shadow-hover;
            transition: @transition-duration-hover;
        }
    }
}

.reference-extra-fields {
    &__container {
    }

    &__heading {
        font-size: @reference-extra-fields-heading-font-size;
        font-weight: @reference-extra-fields-heading-font-weight;

        @media (min-width: @screen-sm-min) {
            margin-top: 0;
        }
    }

    &__list {
        dt {
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
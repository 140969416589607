//! TUOTESIVU (product.php)
//-------------------------

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@product-name-font-size:            @font-size-h2;
@product-price-font-size:           @font-size-h3;
@product-price-font-weight:         700;
@product-price-color:               @brand-success;
@product-price-sale-color:          @brand-danger;

.product {

	&__name {
		font-size: @product-name-font-size;
	}

	&__image-wrapper {
		.make-sm-column(6);
	}

    &__image {
        margin-bottom: @grid-gutter-width;
        text-align: center;

        @media (min-width: @screen-sm) {
            text-align: left;
        }
    }

	&__actions-wrapper {
		.make-sm-column(6);
	}
	
	&__skus {
		margin-bottom: @grid-gutter-width/2;
	}

	&__skus-select {
		@media (max-width: @screen-xs-max) {
			font-size: 16px;
		}
	}
	
	&__actions {
		margin-bottom: @grid-gutter-width/2;
	}

    &__name {
        padding-left: @grid-gutter-width/2;

        @media (min-width: @screen-sm-min) {
            padding-left: 0;
        }
    }
	
	&__image {
		margin-bottom: @grid-gutter-width;
	}

	&__qty-wrapper {
		.make-xs-column(5);
		.make-sm-column(4);
	}

	&__price-wrapper {
		.make-xs-column(7);
		.make-sm-column(8);
	}

	&__price {
		color: @product-price-color;
		font-size: @product-price-font-size;
		font-weight: @product-price-font-weight;

		&--sale {
			color: @product-price-sale-color;
		}

	}

	&__price-value {
	}

	&__buttons {
		.row;
		padding-top: @grid-gutter-width/2;
	}
	
	&__description:extend(.panel, .panel-default) {}
	&__description-header:extend(.panel-heading, .panel-default > .panel-heading) {}
	&__description-body:extend(.panel-body) {}
	
	&__details:extend(.panel, .panel-default) {}
	&__details-header:extend(.panel-heading, .panel-default > .panel-heading) {}
	&__details-body:extend(.panel-body) {}
	
	&__details-list {
		margin: 0;

		dd {
			margin-bottom: @grid-gutter-width/3;
		}

		dd:last-of-type {
			margin-bottom: 0;
		}
	}


    // Ao. tyylit liittyvät Rexelin "€ tai p" -hintasysteemiin
    
    .show-price {
        font-size: @product-price-font-size;

        &__value {
            color: @product-price-color;
            font-weight: @product-price-font-weight;
        }

        &__or {
            color: @text-color;
            display: inline-block;
            font-weight: 400;
            padding: 0 3px;
        }

    }

}

.product-video {
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

@main-container-padding-ms: 20px;
@main-container-padding-sm: 30px;
@main-container-padding-md: 80px;
@main-container-padding-lg: 100px;

.container-fluid {
    @media (min-width: @screen-lg-min) {
        max-width: 1350px;
    }
}

// Header

.site-header {
    margin-bottom: 0;
}

.navbar {
    box-shadow: none;
    border-width: 0;
    border-bottom-width: 1px;

    @media (max-width: @screen-ms-max) {
        background-color: @gray-lighter;
    }
}

.navbar-header {
    @media (min-width: @screen-sm-min) {
        max-width: 250px;
    }

    @media (min-width: @screen-lg-min) {
        max-width: 290px;
    }
}

.navbar-brand {
    @media (max-width: @screen-ms-max) {
        height: 70px;
    }

    img {
        @media (max-width: @screen-ms-max) {
            max-width: 220px;
            margin-top: 8px;
        }

        @media (min-width: @screen-lg-min) {
            max-width: 240px;
            position: relative;
            bottom: 6px;
        }
    }

    @media (min-width: @screen-lg-min) {
        margin-right: 10px;
    }
}

.navbar-form {
    max-width: 200px;
}

.navbar-nav {
    @media (min-width: @screen-sm-min) {
        text-transform: uppercase;
    }
}

// Special rules for SM size
@media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
    .navbar-header {
        margin-top: -35px;
    }

    .navbar-brand {
        padding-bottom: 0;
    }

    .navbar-collapse {
        clear: both;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-nav {
        height: auto;
    }

    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-form {
        margin: 0 0 15px 0;
    }

    .top-bar {
        font-size: @font-size-small - 1;
    }

    .top-bar-links > li {
        position: relative;
        top: 10px;
    }
}

// Containers

.main-container {
    background-color: #fff;
}

.site-header .container-fluid,
.site-footer .container-fluid,
main,
.home-featured__container {
    @media (min-width: @screen-ms-min) {
        padding-left: @main-container-padding-ms;
        padding-right: @main-container-padding-ms;
    }

    @media (min-width: @screen-sm-min) {
        padding-left: @main-container-padding-sm;
        padding-right: @main-container-padding-sm;
    }

    @media (min-width: @screen-md-min) {
        padding-left: @main-container-padding-md;
        padding-right: @main-container-padding-md;
    }

    @media (min-width: @screen-lg-min) {
        padding-left: @main-container-padding-lg;
        padding-right: @main-container-padding-lg;
    }
}

.flex-hero-carousel,
.home-featured {
    margin-left: ~"calc(-1 * @{grid-gutter-width} / 2)";
    width: 100vw;

    @media (min-width: @screen-ms-min) {
        margin-left: ~"calc((@{main-container-padding-ms} + @{grid-gutter-width}/2) * -1)";
        width: ~"calc(100% + (@{main-container-padding-ms} * 2) + @{grid-gutter-width})";
    }

    @media (min-width: @screen-sm-min) {
        margin-left: ~"calc((@{main-container-padding-sm} + @{grid-gutter-width}/2) * -1)";
        width: ~"calc(100% + (@{main-container-padding-sm} * 2) + @{grid-gutter-width})";
    }

    @media (min-width: @screen-md-min) {
        margin-left: ~"calc((@{main-container-padding-md} + @{grid-gutter-width}/2) * -1)";
        width: ~"calc(100% + (@{main-container-padding-md} * 2) + @{grid-gutter-width})";
    }

    @media (min-width: @screen-lg-min) {
        margin-left: ~"calc((@{main-container-padding-lg} + @{grid-gutter-width}/2) * -1)";
        width: ~"calc(100% + (@{main-container-padding-lg} * 2) + @{grid-gutter-width})";
    }
}

.content {
    padding-top: @grid-gutter-width;

    .page-index & {
        padding-top: 0;
    }
}

h1, .h1 {
    @media (max-width: @screen-ms-max) {
        font-size: @font-size-h2;
    }
}

h2, .h2 {
    @media (max-width: @screen-ms-max) {
        font-size: @font-size-h3;
    }
}

h3, .h3 {
    @media (max-width: @screen-ms-max) {
        font-size: @font-size-h4;
    }
}

// Front page

.home-info {
    padding-top: 30px;
    padding-bottom: 40px;

    @media (min-width: @screen-md-min) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.home-featured {
    background-color: @gray-lighter;
    padding-top: 60px;
    padding-bottom: 60px;
}

.flexible-featured-list {
    margin-top: 0;

    &__item {
        @media (max-width: @screen-ms-max) {
            margin-left: auto !important;
            margin-right: auto !important;
            max-width: 400px;
        }
    }

    &__text-content {
        padding-top: @grid-gutter-width/1.5;
        padding-bottom: @grid-gutter-width/1.5;
    }

    &__read-more-link {
        .btn-primary;
    }
}


.item-list {
    display: grid;
    grid-gap: @grid-gutter-width;
    grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    margin: 0; // change?
    list-style: none;
    padding: 0;

    @media (max-width: 500px) {
        margin-left: auto;
        margin-right: auto; 
        max-width: 300px;
    }
}

.item-list__item {
    display: flex;
    flex-direction: column;
    background-color: @gray-lighter;
    text-align: left;
}

.item-list__link-wrapper {
    color: inherit;

    &:hover,
    &:active,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
}

.item-list__text-container {
    margin-top: auto;
    padding: 20px;
}

.item-list__heading {
    color: @text-color;
    font-size: @font-size-base;
    margin-top: 0;
    margin-bottom: 10px;
    min-height: 2.2em;
}

.item-list__info {
    font-size: @font-size-small;
    margin-bottom: 0;
}

.item-list__price {
    color: @brand-primary;
    margin-bottom: 0;
}


.product-images__thumbnail {
    text-align: center;
}

.product-images__thumbnail-img-wrapper {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
}

.print-icon-wrapper {
    margin-top: 30px;
    text-align: right
}

.btn-blue {
    .button-variant(#fff, @brand-primary, transparent);
}

.sidebar {
    @media (min-width: @screen-sm-min) {
        margin-top: 20px;
        position: sticky;
        top: 20px;
    }
}

.mobile-vertical-nav {
    font-weight: 700;
    list-style: none;
    padding-left: 0;

    a {
        display: inline-block;
        padding: 4px 0;
    }
}

.vertical-nav,
.mobile-vertical-nav {
    .toc-h1,
    .toc-h2 {
        a {
            font-size: @vertical-nav-link-font-size;
            font-weight: @vertical-nav-link-font-weight;
        }
    }

    .toc-h3 {
        margin-left: 10px !important;

        a {
            font-size: @vertical-nav-link-font-size;
            font-weight: @vertical-nav-sub-link-font-weight;
        }
    }

    .toc-h4 {
        margin-left: 20px !important;

        a {
            font-size: @vertical-nav-sub-link-font-size - 1;
            font-weight: @vertical-nav-sub-link-font-weight;
        }
    }
}

.navbar-nav .open > a,
.navbar-nav .open > a:hover,
.navbar-nav .open > a:focus {
    border-color: transparent;
}

.housing-company {
    @media (max-width: @screen-ms-max) {
        max-width: 540px;
        margin-left: auto;
        margin-right: auto;
    }
}

.rental-application-form {
    .center-block();
    max-width: 500px;

    @media (min-width: 360px) {
        background-color: #f9f9f9;
        padding: 20px;
    }

    @media (min-width: @screen-sm-min) {
        padding: 30px;
    }

    legend {
        color: @brand-complementary;
        padding-bottom: 5px;
        font-weight: 700;
    }

    .fieldset-section {
        margin-bottom: 30px;
    }
}

.table-rent {
    th, td {
        border: 0 !important;
        padding: 3px 0 !important;
    }
}

.text-wrap {
    white-space: normal !important;
}

.btn-primary {
    font-weight: 400;
}

.navbar-form__button {
    .btn-primary;
}

.row-small-gutters {
    margin-left: -5px;
    margin-right: -5px;

    [class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.responsive-wrapper {
    @media (min-width: @screen-ms-min) {
        background-color: @gray-lighter;
        padding: @grid-gutter-width/1.5;
    }

    @media (min-width: @screen-sm-min) {
        padding: @grid-gutter-width;
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

//! KATEGORIASIVU
//---------------

.category-header {
    .row;
    margin-bottom: @grid-gutter-width;

    &__image {
        .make-sm-column(4);
    }

    &__description {
        .make-sm-column(8);

        &--full-width {
            .make-sm-column(12);
        }
    }
}
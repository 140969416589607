// Alla muuttujia, joita on tarkoitus muokata skin-variables.less -tiedostossa.
// Lisätty myös tänne, etteivät muuttujat aiheuta buildausongelmia aina mergatessa.
@card-box-shadow:           1px 1px 4px 0 rgba(0, 0, 0, 0.15);
@card-box-shadow-hover:     1px 1px 4px 0 rgba(0, 0, 0, 0.3);

html {
    // Estää scrollbarien näkymisen, kun .full-viewport -luokka on käytössä
    overflow-x: hidden;
}

body {
    -moz-osx-font-smoothing: @moz-osx-font-smoothing;
}

// Main-alue = vasen + oikea palsta
main {
    padding-bottom: @grid-gutter-width;

    @media (min-width: @screen-sm) {
        min-height: 500px;
    }
}

// Kaikki kuvat responsiiviseksi
.img-responsive {
	display: inline-block;
}

img {
	&:extend(.img-responsive);
}


.site-header {
    margin-bottom: @grid-gutter-width;
}

.navbar {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    font-family: @navbar-font-family;
    margin-bottom: 0;
}

.navbar-brand {

    font-size: @font-size-h3;
    padding: @logo-padding @logo-padding @logo-padding @grid-gutter-width/2; // Muokkaa tätä, jos näytetäänkin tekstiä eikä logoa

    @media (min-width: @screen-sm-min) {
        padding-left: @grid-gutter-width/2;
    }

    > img {
        display: inline-block;
        height: auto;
        max-height: @navbar-height - (2 * @logo-padding);
        margin: 0;
    }

}

// Menu-nappulalla ei Bootstrapissa oletuksena ole taustaväriä
.navbar-toggle {
    background-color: @navbar-inverse-toggle-bg;
}

//! YLÄPALKKI
// -----------------

.navbar-nav {

    > li {

        > a {

            border-bottom: 1px solid @navbar-link-border-bottom-color;
            font-size: @navbar-font-size;
            font-weight: @navbar-font-weight;

            @media (min-width: @screen-sm) {
                border-bottom: 0;
                border-right: 1px solid rgba(255,255,255,0.1);

                &:first-child {
                    border-left: 1px solid rgba(255,255,255,0.1);
                }
            }

            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                // Linkit hieman tiiviimpään tilaan
                padding-left: 10px;
                padding-right: 10px;
            }

        }
    }

    .open {
        > a,
        > a:hover,
        > a:focus {
            border-color: transparent;
        }

        .dropdown-menu {
            min-width: 200px;
            padding: 0;

            > li {
                > a {
                    border-bottom: 1px solid @navbar-link-border-bottom-color;
                    font-size: @navbar-dropdown-font-size;
                    font-weight: @navbar-dropdown-font-weight;

                    @media (min-width: @screen-sm-min) {
                        border-bottom: 0;
                        padding: @navbar-dropdown-padding-y 10px;
                    }

                    @media (min-width: @screen-md-min) {
                        padding-left: @grid-gutter-width/2;
                        padding-right: @grid-gutter-width/2;
                    }
                }
            }
        }
    }

    @media (min-width: @screen-sm-min) {
        height: @navbar-height;
        // overflow: hidden; // Estää navilinkkien rivittymisen, mutta myös dropdownien näkymisen!
    }

}


// Active-väri samaksi kuin hover-väri
a,
.btn {
	&:active {
		color: @link-hover-color;
	}
}

.btn-primary {
    font-weight: 700;
}


// <ol>-listojen oletuspaddingia pienemmäksi.
// Tulos vaihtelee hieman selaimen mukaan.
ol {
	padding-left: 20px;
}


// Breadcrumbilta poistettiin ennen tässä kohtaa oletustyylit.
// Nyt tyylit nollataan (jos nollataan) skin-variables.less-tiedostossa.
.breadcrumb {
    font-size: @breadcrumb-font-size;

	li {
		// Mobiilikoossa näytetään vain edellinen taso, ei koko murupolkua
		@media (max-width: @screen-xs-max) {
			display: none;

			&:nth-last-child(2) {

				display: inline-block;

				&:before {
					color: inherit;
					content: "«";
					padding: 0 5px;
				}
			}
		}
    }
    
    > li {
        + li:before {
            padding: @breadcrumb-separator-padding;
        }
    }

    a {
        color: @breadcrumb-link-color;
    }
}

// Well-bokseilta pois varjostukset
.well {
	box-shadow: none;
}

.alert {
	margin: @grid-gutter-width/3 0;
}

// .page-headerin marginaali vähän pienemmäksi mobiilikoossa
.page-header {
    @media (max-width: @screen-sm) {
        margin-top: @grid-gutter-width;
    }
}

// Alerteille lievästi lihavoitu teksti luettavuuden parantamiseksi
.alert {
    font-weight: @font-weight-semibold;
}

.popover-title {
    font-weight: @font-weight-semibold;
}


// LOMAKKEET
// ---------

// Bootstrap bug fix. Form groupin sisällä olevat rivit 
// pitää olla samalla välistyksellä kun muutkin formin rivit
.form-group > .row {
	margin-bottom: 15px;
}


label {
	font-size: 0.9em;
	margin-bottom: 3px;
}


// Spinnerit piiloon number-inputeilta
input[type='number'] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


// Lomakekenttien fonttikooksi mobiilissa 16 pikseliä,
// jotta iPhone ei zoomaisi kenttiin.
.form-control {
	@media (max-width: @screen-xs-max) {
		font-size: 16px;
	}
}

// Slick-oletustyylien jyräyksiä

.slick-prev,
.slick-next {
    z-index: 1;
}

.slick-prev:before,
.slick-next:before {
	font-family: FontAwesome;
	font-size: 30px;
}

.slick-prev {
	.square(30px);
	left: 20px;
}

.slick-prev:before {
	content: "";
}

.slick-next {
	.square(30px);
	right: 20px;
}

.slick-next:before {
	content: "";
}


.home-popular {
    margin-top: @grid-gutter-width;
}

// Keskitetään photoswipe-modalin kuvateksti
.pswp__caption__center {
    text-align: center;
}

.fb-page {
    margin-top: @grid-gutter-width/2;
    
    iframe {
        min-height: 110%;
    }
}

.full-width-centered-element {
    margin-left: calc(~"50% - 50vw");
    margin-right: calc(~"50% - 50vw");

    @media (min-width: @screen-lg-min) {
        max-width: 1350px;
    }
}

.full-viewport {
    margin-left: calc(~"50% - 50vw");
    margin-right: calc(~"50% - 50vw");

    &-xs {
        @media (max-width: @screen-xs-max) { .full-width-centered-element(); }
    }

    &-ms {
        @media (max-width: @screen-ms-max) { .full-width-centered-element(); }
    }

    &-sm {
        @media (max-width: @screen-sm-max) { .full-width-centered-element(); }
    }

    &-md {
        @media (max-width: @screen-md-max) { .full-width-centered-element(); }
    }

    &--with-padding {
        padding-left: @grid-gutter-width/2;
        padding-right: @grid-gutter-width/2;
    }
}

.notifications {
    margin-bottom: 15px;

    &__container {
        .container;
    }
}

// Lomakkeenrakentaja
.form-generated {
    // Honeypot
    .form-group.form_field_input_-1_-1 {
        position: absolute;
        left: -9999px;
    }
}

.cart-link {
    &--has-content {
        font-weight: 700;
    }
}

// Seuraavilla 
.has-error {
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline,
    &.radio label,
    &.checkbox label,
    &.radio-inline label,
    &.checkbox-inline label {
        color: @brand-danger;
    }
}

.card {
    box-shadow: @card-box-shadow;

    &:hover {
        box-shadow: @card-box-shadow-hover;
    }
}

// Keep small modals small even on mobile screens
.modal-sm {
    margin: 30px auto;
    width: @modal-sm;
    max-width: 100vw;

    @media (min-width: 360px) {
        max-width: ~"calc(100vw - @{grid-gutter-width})";
    }
}

.cart-continue-btn {
    margin-top: @grid-gutter-width;
    text-align: left;

    @media (min-width: @screen-sm) {
        text-align: right;
    }
}

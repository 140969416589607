// News box, ts. lähinnä kapeaan palstaan tarkoitettu uutislistaus.
// Huom! 1. LESS-muuttuja eli @news-box-has-well määrittää, onko ympärillä boksi vai ei.

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@news-box-has-well:                     false;
@news-box-max-width:                    450px;
@news-box-item-margin-bottom:           10px;
@news-box-img-wrapper-margin-y:         10px;
@news-box-img-border-color:             transparent;
@news-box-img-border-width:             0;
@news-box-text-content-padding-top:     10px;
@news-box-date-color:                   @text-muted;
@news-box-date-font-size:               @font-size-small;
@news-box-date-font-weight:             700;
@news-box-date-margin-bottom:           1px;
@news-box-heading-font-size:            @font-size-h4;
@news-box-heading-font-weight:          @headings-font-weight;
@news-box-heading-line-height:          1.2;
@news-box-heading-margin-bottom:        5px;
@news-box-heading-text-transform:       none;
@news-box-heading-active-color:         @text-muted;
@news-box-text-font-size:               @font-size-base;
@news-box-text-line-height:             1.3;
@news-box-read-more-link-display:       inline;
@news-box-read-more-link-font-size:     @font-size-small;
@news-box-read-more-link-font-weight:   @font-weight-semibold;
@news-box-read-more-link-margin-top:    2px;
@news-box-read-more-link-icon:          "\f105";
@news-box-read-more-link-icon-margin:   5px;
@news-box-news-page-link-font-weight:   @font-weight-semibold;
@news-box-news-page-link-margin-top:    2px;
@news-box-news-page-link-icon:          "\f105";
@news-box-news-page-link-icon-margin:   5px;


.news-box {
    max-width: @news-box-max-width;

    & when (@news-box-has-well = true) {
        .well();
    }

    &__main-heading {
        margin-top: 0;
    }

    &__item {
        margin-bottom: @news-box-item-margin-bottom;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__img-wrapper {
        & when (@news-box-has-well = true) {
            border: 1px solid @well-border;
        }

        margin: @news-box-img-wrapper-margin-y 0;
    }

    img {
        border: @news-box-img-border-width solid @news-box-img-border-color;
    }

    &__date {
        color: @news-box-date-color;
        font-size: @news-box-date-font-size;
        font-weight: @news-box-date-font-weight;
        margin-bottom: @news-box-date-margin-bottom;
    }

    &__heading {
        font-size: @news-box-heading-font-size;
        font-weight: @news-box-heading-font-weight;
        line-height: @news-box-heading-line-height;
        margin-top: 0;
        margin-bottom: @news-box-heading-margin-bottom;
        text-transform: @news-box-heading-text-transform;
    }

    &__heading-link {
        &--active {
            color: @news-box-heading-active-color;
        }
    }

    &__text-content {
        padding-top: @news-box-text-content-padding-top;
    }

    &__text {
        font-size: @news-box-text-font-size;
        line-height: @news-box-text-line-height;
        margin-bottom: 0;
    }

    &__read-more-link {
        display: @news-box-read-more-link-display;
        font-size: @news-box-read-more-link-font-size;
        font-weight: @news-box-read-more-link-font-weight;
        margin-top: @news-box-read-more-link-margin-top;
        white-space: nowrap;

        &:after when not (@news-box-read-more-link-icon = "") {
            .fa();
            content: @news-box-read-more-link-icon;
            margin-left: @news-box-read-more-link-icon-margin;
        }
    }

    &__news-page-link {
        display: inline-block;
        font-weight: @news-box-news-page-link-font-weight;
        margin-top: 10px;

        &:after when not (@news-box-news-page-link-icon = "") {
            .fa();
            content: @news-box-news-page-link-icon;
            margin-left: @news-box-news-page-link-icon-margin;
        }
    }
}
.order-pager {

    .make-row();

    &__forward {
        .make-xs-column(10);
        .make-xs-column-push(2);
        .make-sm-column(6);
        .make-sm-column-push(6);
        text-align: right;
    }

    &__back {
        .make-xs-column(2);
        .make-xs-column-pull(10);
        .make-sm-column(6);
        .make-sm-column-pull(6);
        text-align: left;
    }

    &__back-button {
        .btn;
        .btn-default;

        @media (min-width: @screen-sm) {
            .btn-link;
        }
    }

}
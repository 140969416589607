@flex-hero-bg:                                  @gray-darker;
@flex-hero-bg-position:                         center center;
@flex-hero-color:                               #fff;
@flex-hero-height:                              ~"calc(100vh - @{navbar-height} - 60px)";
@flex-hero-height-fallback:                     800px;
@flex-hero-max-height-mobile:                   600px;
@flex-hero-max-height-desktop:                  1000px;
@flex-hero-logo-margin-top:                     20px;
@flex-hero-link-color:                          @link-color;
@flex-hero-link-hover-color:                    @link-hover-color;
@flex-hero-link-font-weight:                    inherit;

@flex-hero-text-content-box:                    true; // Jos true, tekstin taustalla on läpikuultava boksi
@flex-hero-text-content-bg:                     rgba(0, 0, 0, 0.3);
@flex-hero-text-content-padding:                20px;
@flex-hero-text-content-width-mobile:           100%;
@flex-hero-text-content-width-desktop:          80%;

@flex-hero-heading-color:                       @flex-hero-color;
@flex-hero-heading-font-size-xs:                7vw;
@flex-hero-heading-font-size-ms:                6vw;
@flex-hero-heading-font-size-sm:                5vw;
@flex-hero-heading-font-size-md:                5vw;
@flex-hero-heading-font-size-lg:                6vw;
@flex-hero-heading-font-weight:                 @headings-font-weight;
@flex-hero-heading-margin-top:                  20px;
@flex-hero-heading-margin-bottom:               20px;
@flex-hero-heading-text-transform:              uppercase;

@flex-hero-text-font-weight:                    400;
@flex-hero-text-font-size-xs:                   @font-size-h4;
@flex-hero-text-font-size-ms:                   @font-size-h3;
@flex-hero-text-font-size-sm:                   @font-size-h2;
@flex-hero-text-font-size-md:                   @font-size-h1;
@flex-hero-text-font-size-lg:                   @font-size-h1;
@flex-hero-text-shadow:                         2px 2px 10px rgba(0, 0, 0, 1.0);

@flex-hero-cta-button-style:                    true; // Jos true, CTA-linkki saa nappulan kaltaiset tyylit
@flex-hero-link-color:                          @flex-hero-color;
@flex-hero-link-hover-bg:                       @btn-primary-bg;
@flex-hero-link-hover-color:                    @flex-hero-link-color;
@flex-hero-link-font-size:                      @font-size-small;
@flex-hero-link-font-weight:                    700;
@flex-hero-link-margin-top:                     20px;
@flex-hero-link-padding:                        10px 20px;
@flex-hero-link-text-decoration:                underline;
@flex-hero-link-hover-text-decoration:          none;
@flex-hero-link-btn-bg:                         @btn-primary-bg;
@flex-hero-link-btn-hover-bg:                   darken(@btn-primary-bg, 5%);
@flex-hero-link-btn-color:                      #fff;
@flex-hero-link-btn-hover-color:                @flex-hero-link-btn-color;

@flex-hero-scroll-arrow-color:                  #fff;
@flex-hero-scroll-arrow-hover-color:            @gray-lighter;
@flex-hero-scroll-arrow-font-size:              4em;
@flex-hero-scroll-arrow-padding:                10px;

.flex-hero {

    background-color: @flex-hero-bg;
    background-position: @flex-hero-bg-position;
    background-repeat: no-repeat;
    background-size: cover;
    color: @flex-hero-color;
    height: @flex-hero-height-fallback;
    height: @flex-hero-height;
    max-height: @flex-hero-max-height-mobile;
    position: relative;

    @media (min-width: @screen-sm-min) {
        max-height: @flex-hero-max-height-desktop;
    }

    @media (min-width: @screen-sm-min) and (max-height: 700px) {
        min-height: 700px;
    }

    &__container {
        .container();
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__logo {
        margin-top: @flex-hero-logo-margin-top;
    }

    &__text-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: @flex-hero-text-font-size-xs;
        font-weight: @flex-hero-text-font-weight;
        line-height: 1.2;
        max-width: @flex-hero-text-content-width-mobile;
        text-align: center;
        text-shadow: @flex-hero-text-shadow;

        & when (@flex-hero-text-content-box = true) {
            background-color: @flex-hero-text-content-bg;
            padding: @flex-hero-text-content-padding;
        }

        @media (min-width: @screen-ms-min) {
            font-size: @flex-hero-text-font-size-ms;
        }

        @media (min-width: @screen-sm-min) {
            font-size: @flex-hero-text-font-size-sm;
            max-width: @flex-hero-text-content-width-desktop;
        }

        @media (min-width: @screen-md-min) {
            font-size: @flex-hero-text-font-size-md;
        }

        @media (min-width: @screen-lg-min) {
            font-size: @flex-hero-text-font-size-lg;
        }
    }

    &__heading {
        color: @flex-hero-heading-color;
        font-size: @flex-hero-heading-font-size-xs;
        font-weight: @flex-hero-heading-font-weight;
        line-height: 1.0;
        margin-top: @flex-hero-heading-margin-top;
        margin-bottom: @flex-hero-heading-margin-bottom;
        text-transform: @flex-hero-heading-text-transform;

        @media (min-width: @screen-ms-min) {
            font-size: @flex-hero-heading-font-size-ms;
        }

        @media (min-width: @screen-sm-min) {
            font-size: @flex-hero-heading-font-size-sm;
        }

        @media (min-width: @screen-md-min) {
            font-size: @flex-hero-heading-font-size-md;
        }

        @media (min-width: @screen-lg-min) {
            font-size: @flex-hero-heading-font-size-lg;
        }
    }

    &__body {
        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    &__cta-link {
        color: @flex-hero-link-color;
        display: inline-block;
        font-size: @flex-hero-link-font-size;
        font-weight: @flex-hero-link-font-weight;
        margin-top: @flex-hero-link-margin-top;
        text-decoration: @flex-hero-link-text-decoration;
        text-transform: uppercase;
        letter-spacing: 0.02em;

        & when (@flex-hero-cta-button-style = true) {
            background-color: @flex-hero-link-btn-bg;
            border-radius: @btn-border-radius-base;
            color: @flex-hero-link-btn-color;
            line-height: 1;
            padding: @flex-hero-link-padding;
            text-decoration: none;
            text-shadow: none;

            &:hover {
                background-color: @flex-hero-link-btn-hover-bg;
                color: @flex-hero-link-btn-hover-color;
                text-decoration: none;
            }
        }

        &:hover,
        &:active,
        &:focus {
            color: @flex-hero-link-hover-color;
            text-decoration: @flex-hero-link-hover-text-decoration;
        }
    }

    &__scroll-arrow {
        position: absolute;
        left: 40%;
        bottom: 10px;
        width: 20%;
        text-align: center;
    }

    &__scroll-arrow-link {
        color: @flex-hero-scroll-arrow-color;
        display: inline-block;
        font-size: @flex-hero-scroll-arrow-font-size;
        line-height: 1.0;
        padding: @flex-hero-scroll-arrow-padding;

        &:hover,
        &:active,
        &:focus {
            color: @flex-hero-scroll-arrow-hover-color;
        }
    }

}

// The following styles prevent the blocks from stacking during page load

.flex-hero-carousel {
    overflow-y: hidden;
}

.flex-hero:nth-child(n+1) {
    display: none;
}

.slick-initialized .flex-hero,
.flex-hero:first-child {
    display: block;
}

// .navbar-form = hakukenttä

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@navbar-form-input-bg:                  lighten(@brand-primary, 10%);
@navbar-form-input-color:               #fff;
@navbar-form-input-placeholder-color:   rgba(255, 255, 255, 0.7);
@navbar-form-input-border:              darken(@brand-primary, 5%);
@navbar-form-font-size:                 @font-size-base;
@navbar-form-input-focus-border:        lighten(@brand-primary, 20%);
@navbar-form-button-bg:                 darken(@brand-primary, 10%);
@navbar-form-button-border:             @navbar-form-button-bg;
@navbar-form-button-hover-bg:           darken(@brand-primary, 20%);
@navbar-form-button-hover-border:       @navbar-form-button-hover-bg;
@transition-duration-default:           0.4s;
@transition-duration-hover:             0.2s;

.navbar-form {

    @media (min-width: @screen-sm-min) {
        padding-right: 0;
    }

    @media (max-width: @screen-ms-max) {
        margin-top: 0;
        margin-bottom: 0;
    }

    &__input {

        border-right: 0;
        font-size: 16px;

        @media (min-width: @screen-sm-min) {
            background-color: @navbar-form-input-bg;
            border-color: @navbar-form-input-border;
            color: @navbar-form-input-color;
            padding: 6px 12px;
            height: (@line-height-base * (@font-size-base - 1)) + (6px * 2) + 2;
            font-size: @navbar-form-font-size;
            .placeholder(@navbar-form-input-placeholder-color);
        }

        &:focus {
            box-shadow: none;
            border-color: @navbar-form-input-focus-border;
        }
    }

    &__button {
        .btn;

        font-size: @navbar-form-font-size - 1;
        transition-duration: @transition-duration-default;

        @media (min-width: @screen-sm-min) {
            background-color: @navbar-form-button-bg;
            border-color: @navbar-form-button-border;
            padding: 6px 12px;
        }

        &:hover {
            transition-duration: @transition-duration-hover;

            @media (min-width: @screen-sm-min) {
                background: @navbar-form-button-hover-bg;
                border-color: @navbar-form-button-hover-border;
            }
        }
    }
}

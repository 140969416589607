//! SIVUNAVIGAATIO
// ---------------

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@vertical-nav-border-color:                    #eee;
@vertical-nav-margin-bottom:                   @grid-gutter-width;
@vertical-nav-link-font-size:                  @font-size-base;
@vertical-nav-link-font-weight:                700;
@vertical-nav-link-bg:                         #fbfbfb;
@vertical-nav-link-color:                      @link-color;
@vertical-nav-link-border-color:               @vertical-nav-border-color;
@vertical-nav-link-hover-bg:                   darken(@vertical-nav-link-bg, 3%);
@vertical-nav-link-hover-color:                @text-color;
@vertical-nav-link-active-bg:                  #eee;
@vertical-nav-link-active-color:               @text-color;
@vertical-nav-link-active-font-weight:         700;
@vertical-nav-link-trail-bg:                   @vertical-nav-link-bg;
@vertical-nav-link-trail-color:                @vertical-nav-link-active-color;
@vertical-nav-link-trail-font-weight:          @vertical-nav-link-active-font-weight;
@vertical-nav-link-padding-x:                  15px;
@vertical-nav-link-padding-y:                  6px;
@vertical-nav-link-text-transform:             none;
@vertical-nav-sub-link-show-icon:              1;
@vertical-nav-sub-link-icon:                   "\f105";
@vertical-nav-sub-link-icon-opacity:           0.5;
@vertical-nav-sub-link-font-size:              @vertical-nav-link-font-size - 1;
@vertical-nav-sub-link-font-weight:            400;
@vertical-nav-sub-link-active-font-weight:     @vertical-nav-sub-link-font-weight;
@vertical-nav-sub-link-trail-font-weight:      @vertical-nav-sub-link-active-font-weight;
@vertical-nav-sub-link-text-transform:         none;
@vertical-nav-has-children-link-show-icon:     1;
@vertical-nav-has-children-link-icon:          "";
@vertical-nav-has-children-link-icon-opacity:  0.9;

.vertical-nav {

    border: 1px solid @vertical-nav-border-color;
    border-bottom: 0;
    list-style: none;
    margin-bottom: @vertical-nav-margin-bottom;
    padding: 0;

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        display: block;
        line-height: 1.3;
        margin: 0 !important;
    }

    > li {

        // Kaikkien tasojen linkit
        a {
            background: @vertical-nav-link-bg;
            border-bottom: 1px solid @vertical-nav-link-border-color;
            color: @vertical-nav-link-color;
            display: block;
            padding: @vertical-nav-link-padding-y @vertical-nav-link-padding-x;

            &:hover,
            &:active,
            &:focus {
                background: @vertical-nav-link-hover-bg;
                color: @vertical-nav-link-hover-color;
                text-decoration: none;
            }
        }

        // 1. tason linkit
        > a {
            font-size: @vertical-nav-link-font-size;
            font-weight: @vertical-nav-link-font-weight;
            text-transform: @vertical-nav-link-text-transform;
        }

        > ul {

            // 2. ja tätä alemman tason linkit
            a {
                font-size: @vertical-nav-sub-link-font-size;
                font-weight: @vertical-nav-sub-link-font-weight;

                &:before when (@vertical-nav-sub-link-show-icon = 1) {
                    content: @vertical-nav-sub-link-icon;
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    margin-right: 5px;
                    opacity: @vertical-nav-sub-link-icon-opacity;
                }
            }

            // Alemman tason linkeille lisäpaddingia aina 5. tasoon asti
            > li > ul > li > a {
                padding-left: @vertical-nav-link-padding-x + 10;
            }

            > li > ul > li > ul > li > a {
                padding-left: @vertical-nav-link-padding-x + 20;
            }

            > li > ul > li > ul > li > ul > li > a {
                padding-left: @vertical-nav-link-padding-x + 30;
            }

        }

        &.has-children {
            > a {
                &:after when (@vertical-nav-has-children-link-show-icon = 1) {
                    content: @vertical-nav-has-children-link-icon;
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    margin-left: 5px;
                    opacity: @vertical-nav-has-children-link-icon-opacity;
                }
            }
        }

    }

    li.is-trail {
        > a {
            background: @vertical-nav-link-trail-bg;
            color: @vertical-nav-link-trail-color;

            &:hover,
            &:active,
            &:focus {
                background: @vertical-nav-link-hover-bg;
                color: @vertical-nav-link-hover-color;
            }
        }
    }

    ul > li.is-trail {
        > a {
            font-weight: @vertical-nav-sub-link-trail-font-weight;

            &:hover,
            &:active,
            &:focus {
                background: @vertical-nav-link-hover-bg;
                color: @vertical-nav-link-hover-color;
            }
        }
    }


    li.is-active {
        > a {
            background: @vertical-nav-link-active-bg;
            color: @vertical-nav-link-active-color;

            &:hover,
            &:active,
            &:focus {
                background: @vertical-nav-link-hover-bg;
                color: @vertical-nav-link-hover-color;
            }
        }
    }

    ul > li.is-active {
        > a {
            font-weight: @vertical-nav-sub-link-active-font-weight;

            &:hover,
            &:active,
            &:focus {
                background: @vertical-nav-link-hover-bg;
                color: @vertical-nav-link-hover-color;
            }
        }
    }
}
@home-info-bg:                          transparent;
@home-info-color:                       @text-color;
@home-info-padding-mobile:              30px @grid-gutter-width/2;
@home-info-padding-desktop:             50px @grid-gutter-width;
@home-info-text-content-max-width:      800px;
@home-info-heading-color:               @headings-color;
@home-info-heading-font-size-mobile:    @font-size-h1;
@home-info-heading-font-size-desktop:   @font-size-h1 * @font-scale-factor;
@home-info-heading-margin-mobile:       @grid-gutter-width/2;
@home-info-heading-margin-desktop:      @grid-gutter-width;
@home-info-heading-text-align-mobile:   left;
@home-info-heading-text-align-desktop:  center;
@home-info-lead-font-weight:            700;
@home-info-body-font-size-mobile:       @font-size-base;
@home-info-body-font-size-desktop:      @font-size-large;
@home-info-body-text-align-mobile:      left;
@home-info-body-text-align-desktop:     center;

.home-info {
    background: @home-info-bg;
    color: @home-info-color;
    padding: @home-info-padding-mobile;

    @media (min-width: @screen-sm-min) {
        padding: @home-info-padding-desktop;
    }

    &__text-content {
        margin-left: auto;
        margin-right: auto;
        max-width: @home-info-text-content-max-width;

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__heading {
        color: @home-info-heading-color;
        font-size: @home-info-heading-font-size-mobile;
        margin-top: 0;
        margin-bottom: @home-info-heading-margin-mobile;
        text-align: @home-info-heading-text-align-mobile;

        @media (min-width: @screen-md-min) {
            font-size: @home-info-heading-font-size-desktop;
            margin-bottom: @home-info-heading-margin-desktop;
            text-align: @home-info-heading-text-align-desktop;
        }
    }

    &__lead,
    &__body {
        font-size: @home-info-body-font-size-mobile;
        text-align: @home-info-body-text-align-mobile;

        @media (min-width: @screen-md-min) {
            font-size: @home-info-body-font-size-desktop;
            text-align: @home-info-body-text-align-desktop;
        }
    }

    &__lead {
        font-weight: @home-info-lead-font-weight;
    }

    &__body {
        > :last-child {
            margin-bottom: 0;
        }
    }
}

// Primeon lisäyksiä Bootstrapiin
// ------------------------------

// ms-kokoluokka xs:n ja sm:n väliin
// ---------------------------------

@screen-ms-min: 480px;
@screen-ms: @screen-ms-min;
@screen-ms-max: (@screen-sm-min - 1);

.make-ms-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left:  (@gutter / 2);
    padding-right: (@gutter / 2);

    @media (min-width: @screen-ms-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

.make-ms-column-offset(@columns) {
    @media (min-width: @screen-ms-min) {
    margin-left: percentage((@columns / @grid-columns));
    }
}

.make-ms-column-push(@columns) {
    @media (min-width: @screen-ms-min) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-ms-column-pull(@columns) {
    @media (min-width: @screen-ms-min) {
        right: percentage((@columns / @grid-columns));
    }
}


.visible-ms-block,
.visible-ms-inline,
.visible-ms-inline-block {
    display: none !important;
}

.visible-ms {
    .responsive-invisibility();
}

.visible-ms {
    @media (min-width: @screen-ms-min) and (max-width: @screen-ms-max) {
        .responsive-visibility();
    }
}

.visible-ms-block {
    @media (min-width: @screen-ms-min) and (max-width: @screen-ms-max) {
        display: block !important;
    }
}

.visible-ms-inline {
    @media (min-width: @screen-ms-min) and (max-width: @screen-ms-max) {
        display: inline !important;
    }
}

.visible-ms-inline-block {
    @media (min-width: @screen-ms-min) and (max-width: @screen-ms-max) {
        display: inline-block !important;
    }
}


.hidden-ms {
    @media (min-width: @screen-ms-min) and (max-width: @screen-ms-max) {
        .responsive-invisibility();
    }
}
// Edustaa uutissivun yksittäisen uutisen näkymää

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@news-article-mobile-max-width:         450px;
@news-article-img-border-width:         1px;
@news-article-img-border-color:         @gray-lighter;
@news-article-img-margin-bottom:        10px;
@news-article-img-max-width:            450px;
@news-article-heading-margin-bottom:    10px;
@news-article-date-color:               @text-muted;
@news-article-date-font-size:           inherit;
@news-article-date-font-weight:         700;
@news-article-lead-font-weight:         700;

.news-article {

    max-width: @news-article-mobile-max-width;

    @media (min-width: @screen-sm-min) {
        max-width: none;
    }

    &__heading {
        margin-top: 0;
        margin-bottom: @news-article-heading-margin-bottom;
    }

    &__img-container {
        max-width: @news-article-img-max-width;
    }

    &__img {
        border: @news-article-img-border-width solid @news-article-img-border-color;
        margin-bottom: @news-article-img-margin-bottom;
    }

    &__text-content {

    }

    &__date {
        color: @news-article-date-color;
        font-size: @news-article-date-font-size;
        font-weight: @news-article-date-font-weight;
    }

    &__lead {
        font-weight: @news-article-lead-font-weight;
    }

    &__text {

    }

}
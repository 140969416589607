// Ylimääräinen yläpalkki varsinaisen navbarin yläpuolella
// top-bar.less sisältää rakenteen & yleiset tyylit,
// alla listaelementteihin liittyvät tyylit.

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@top-bar-link-color:            #fff;
@top-bar-link-hover-color:      @gray-light;
@top-bar-sub-link-bg:           transparent;
@top-bar-sub-link-color:        @dropdown-link-color;
@top-bar-sub-link-font-size:    @top-bar-font-size;
@top-bar-sub-link-font-weight:  @font-weight-semibold;
@top-bar-sub-link-hover-bg:     @gray-lighter;
@top-bar-sub-link-hover-color:  @gray-dark;
@top-bar-sub-link-padding-y:    7px;

.top-bar-links {

    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        display: inline-block;

        &:first-child {
            > a {
                margin-left: 0;
            }
        }

        &:last-child {
            > a {
                margin-right: 0;
            }
        }

        > a {
            color: @top-bar-link-color;
            display: block;
            margin: 0 5px;
            padding: 8px 0;

            @media (min-width: @screen-sm-min) {
                margin: 0 10px;
            }

            &:hover,
            &:active,
            &:focus {
                color: @top-bar-link-hover-color;
                text-decoration: none;
            }
        }

        > ul {

            > li {
                display: block;

                > a {
                    background: @top-bar-sub-link-bg !important;
                    color: @top-bar-sub-link-color !important;
                    font-size: @top-bar-sub-link-font-size !important;
                    font-weight: @top-bar-sub-link-font-weight !important;
                    margin: 0;
                    padding-top: @top-bar-sub-link-padding-y !important;
                    padding-bottom: @top-bar-sub-link-padding-y !important;
                    text-transform: none;

                    &:hover,
                    &:active,
                    &:focus {
                        background: @top-bar-sub-link-hover-bg !important;
                        color: @top-bar-sub-link-hover-color !important;
                    }
                }
            }
        }

    }

    .fa {
        @media (max-width: @screen-xs-max) {
            .fa-lg;
        }
    }

}

.top-bar {
    &__link-area {
        &--user {
            .dropdown-menu {
                // Huom! Alla olevat tyylit toimivat vain oikean reunan linkeillä.
                // Jos vasempaan reunaan tulee dropdowneja, tämä täytyy muuttaa.
                left: auto;
                right: 0;
            }
        }
    }
}

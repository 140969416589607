@one-pager-section-bg:                          #fff;
@one-pager-section-color:                       @text-color;
@one-pager-section-bg-position-mobile:          top center;
@one-pager-section-bg-position-desktop:         center center;
@one-pager-section-height:                      100vh;
@one-pager-section-height-fallback:             700px;
@one-pager-section-max-height:                  1000px;

@one-pager-section-text-padding-top-mobile:     140px;
@one-pager-section-text-margin-top-desktop:     100px;
@one-pager-section-text-margin-bottom-desktop:  30px;
@one-pager-section-text-bg-mobile:              rgba(255, 255, 255, 0.85);
@one-pager-section-text-bg-desktop:             rgba(255, 255, 255, 0.75);
@one-pager-section-text-padding-bottom-mobile:  40px;

@one-pager-section-heading-color:               @headings-color;
@one-pager-section-heading-font-size:           @font-size-h1;
@one-pager-section-heading-font-weight:         @headings-font-weight;
@one-pager-section-body-font-size:              @font-size-base;
@one-pager-section-lead-font-size:              @one-pager-section-body-font-size;
@one-pager-section-lead-font-weight:            700;

@one-pager-section-form-margin-top:             0;
@one-pager-section-form-margin-bottom:          0;

@one-pager-jumbotron-bg:                        @gray-darker;
@one-pager-jumbotron-bg-position:               center center;
@one-pager-jumbotron-color:                     #fff;
@one-pager-jumbotron-height:                    100vh;
@one-pager-jumbotron-height-fallback:           700px;
@one-pager-jumbotron-max-height-mobile:         800px;
@one-pager-jumbotron-logo-margin-top:           20px;
@one-pager-jumbotron-link-color:                @link-color;
@one-pager-jumbotron-link-hover-color:          @link-hover-color;
@one-pager-jumbotron-link-font-weight:          inherit;

@one-pager-jumbotron-text-position-top-mobile:  35%;
@one-pager-jumbotron-text-position-top-desktop: 35%;
@one-pager-jumbotron-text-width-mobile:         90%;
@one-pager-jumbotron-text-width-desktop:        60%;
@one-pager-jumbotron-text-font-weight:          400;
@one-pager-jumbotron-text-font-size-xs:         @font-size-h3;
@one-pager-jumbotron-text-font-size-ms:         @font-size-h3;
@one-pager-jumbotron-text-font-size-sm:         @font-size-h2;
@one-pager-jumbotron-text-font-size-md:         @font-size-h1;
@one-pager-jumbotron-text-font-size-lg:         @font-size-h1;
@one-pager-jumbotron-text-shadow:               2px 2px 10px rgba(0, 0, 0, 1.0);

@one-pager-jumbotron-heading-color:             @one-pager-jumbotron-color;
@one-pager-jumbotron-heading-font-size-xs:      36px;
@one-pager-jumbotron-heading-font-size-ms:      48px;
@one-pager-jumbotron-heading-font-size-sm:      68px;
@one-pager-jumbotron-heading-font-size-md:      90px;
@one-pager-jumbotron-heading-font-size-lg:      @one-pager-jumbotron-heading-font-size-md;
@one-pager-jumbotron-heading-font-weight:       @headings-font-weight;
@one-pager-jumbotron-heading-margin-bottom:     30px;
@one-pager-jumbotron-heading-text-transform:    uppercase;

@one-pager-jumbotron-link-margin-top:           20px;


.one-pager-section {

    background-color: @one-pager-section-bg;
    background-position: @one-pager-section-bg-position-mobile;
    background-repeat: no-repeat;
    background-size: cover;
    color: @one-pager-section-color;

    @media (min-width: @screen-sm-min) {
        background-position: @one-pager-section-bg-position-desktop;
    }

    &--full-height {
        @media (min-width: @screen-sm-min) {
            min-height: @one-pager-section-height-fallback;
            min-height: 100vh;
        }
    }

    &__row {

    }

    &__text-column {

        background-color: @one-pager-section-text-bg-mobile;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, @one-pager-section-text-bg-mobile @one-pager-section-text-padding-top-mobile - 20px);
        padding-top: @one-pager-section-text-padding-top-mobile;

        @media (min-width: @screen-sm-min) {
            background-color: @one-pager-section-text-bg-desktop;
            background-image: none;
            min-height: @one-pager-section-height-fallback;
            min-height: @one-pager-section-height;
            padding-top: 0;
        }
    }

    &--even {
        .one-pager-section__text-column {
            .make-sm-column-offset(5);
            .make-md-column-offset(7);
        }
    }

    &__text-content {
        margin: 0 auto;
        max-width: 400px;
        padding: 0 0 @one-pager-section-text-padding-bottom-mobile 0;

        @media (min-width: @screen-sm-min) {
            margin-top: @one-pager-section-text-margin-top-desktop;
            margin-bottom: @one-pager-section-text-margin-bottom-desktop;
            padding: 0 30px 0 30px;
        }

        p, ul, ol {
            margin-bottom: @grid-gutter-width / 1.5;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__heading {
        color: @one-pager-section-heading-color;
        font-size: @one-pager-section-heading-font-size;
        margin-top: 0;
        margin-bottom: @grid-gutter-width;
    }

    &__lead {
        font-size: @one-pager-section-lead-font-size;
        font-weight: @one-pager-section-lead-font-weight;
    }

    .form {
        margin-top: @one-pager-section-form-margin-top;
        margin-bottom: @one-pager-section-form-margin-bottom;
    }

}


.one-pager-jumbotron {

    background-color: @one-pager-jumbotron-bg;
    background-position: @one-pager-jumbotron-bg-position;
    background-repeat: no-repeat;
    background-size: cover;
    color: @one-pager-jumbotron-color;
    height: @one-pager-jumbotron-height-fallback;
    height: @one-pager-jumbotron-height;
    max-height: @one-pager-jumbotron-max-height-mobile;
    position: relative;

    @media (min-width: @screen-sm-min) {
        max-height: none;
    }

    @media (min-width: @screen-sm-min) and (max-height: 700px) {
        min-height: 700px;
    }

    &__logo {
        margin-top: @one-pager-jumbotron-logo-margin-top;
    }

    &__text-content {
        font-size: @one-pager-jumbotron-text-font-size-xs;
        font-weight: @one-pager-jumbotron-text-font-weight;
        line-height: 1.2;
        position: absolute;
        left: (100%-(@one-pager-jumbotron-text-width-mobile))/2;
        top: @one-pager-jumbotron-text-position-top-mobile;
        width: @one-pager-jumbotron-text-width-mobile;
        text-align: center;
        text-shadow: @one-pager-jumbotron-text-shadow;

        @media (min-width: @screen-ms-min) {
            font-size: @one-pager-jumbotron-text-font-size-ms;
        }

        @media (min-width: @screen-sm-min) {
            font-size: @one-pager-jumbotron-text-font-size-sm;
            left: (100%-(@one-pager-jumbotron-text-width-desktop))/2;
            top: @one-pager-jumbotron-text-position-top-desktop;
            width: @one-pager-jumbotron-text-width-desktop;
        }

        @media (min-width: @screen-md-min) {
            font-size: @one-pager-jumbotron-text-font-size-md;
        }

        @media (min-width: @screen-lg-min) {
            font-size: @one-pager-jumbotron-text-font-size-lg;
        }
    }

    &__heading {
        color: @one-pager-jumbotron-heading-color;
        font-size: @one-pager-jumbotron-heading-font-size-xs;
        font-weight: @one-pager-jumbotron-heading-font-weight;
        line-height: 1.0;
        margin-bottom: @one-pager-jumbotron-heading-margin-bottom;
        text-transform: @one-pager-jumbotron-heading-text-transform;

        @media (min-width: @screen-ms-min) {
            font-size: @one-pager-jumbotron-heading-font-size-ms;
        }

        @media (min-width: @screen-sm-min) {
            font-size: @one-pager-jumbotron-heading-font-size-sm;
        }

        @media (min-width: @screen-md-min) {
            font-size: @one-pager-jumbotron-heading-font-size-md;
        }

        @media (min-width: @screen-lg-min) {
            font-size: @one-pager-jumbotron-heading-font-size-lg;
        }
    }

    &__body {
        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    &__cta-link {
        color: @one-pager-jumbotron-link-color;
        display: inline-block;
        font-weight: @one-pager-jumbotron-link-font-weight;
        margin-top: @one-pager-jumbotron-link-margin-top;

        &:hover,
        &:active,
        &:focus {
            color: @one-pager-jumbotron-link-hover-color;
        }
    }

}
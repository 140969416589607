// Alareunan slideshow-karuselli
// 
// Alla olevia oletustyylejä vastaava JavaScript:
// 
// $('.slideshow__slides').slick({
// 	arrows: true,
// 	autoplay: true,
// 	autoplaySpeed: 4000,
// 	centerMode: true,
// 	infinite: true,
// 	slidesToShow: 1,
// 	slidesToScroll: 2,
// 	variableWidth: true
// });

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@slideshow-heading-bg:                  transparent;
@slideshow-heading-color:               inherit;
@slideshow-heading-font-size:           @font-size-h1;
@slideshow-heading-font-weight:         400;
@slideshow-slide-width:                 280px;
@slideshow-slide-height:                280px;
@slideshow-slide-heading-font-weight:   400;
@slideshow-description-font-size:       @font-size-base - 2;
@slideshow-link-font-weight:            700;
@slideshow-link-font-size:              @font-size-base;

.slideshow {

	&__section-heading {
		background: @slideshow-heading-bg;
		color: @slideshow-heading-color;
		font-size: @slideshow-heading-font-size;
		font-weight: @slideshow-heading-font-weight;
		margin-bottom: @grid-gutter-width/2;
		text-align: center;
	}

	&__slides {
		margin-bottom: 0;
	}

	&__slide {
		color: #fff;
		height: @slideshow-slide-height;
		width: @slideshow-slide-width;
		position: relative;
	}

	&__caption {
		text-align: center;
	}

	&__heading {
		color: #fff;
		font-weight: @slideshow-slide-heading-font-weight;
		margin: 0;
		padding: @grid-gutter-width/2;
		text-shadow: 1px 1px rgba(0,0,0,0.3);
	}

	&__description {
		font-size: @slideshow-description-font-size;
		height: 1.3em*5;
		line-height: 1.3em;
		overflow: hidden;
		padding-left: @grid-gutter-width/2;
		padding-right: @grid-gutter-width/2;
	}

	&__link-wrapper {
		color: #fff;
		display: block;
		height: @slideshow-slide-height;
		text-decoration: none !important;

		&:hover {
			color: #fff;
			text-decoration: none !important;
		}
	}

	&__inner-wrapper {
		background: rgba(0,0,0,0.5);
		display: block;
		height: @slideshow-slide-height;
		width: 100%;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 0.3s ease;

		.slideshow__slide:hover & {
			opacity: 1.0;
		}
	}

	&__link {
		.btn;
		.btn-block;
		.btn-primary;
		padding: @grid-gutter-width/2;
		position: absolute;
		bottom: 0;
		font-weight: @slideshow-link-font-weight;
		font-size: @slideshow-link-font-size;
		text-align: center;
		width: 100%;
	}

}
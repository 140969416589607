// Tuotelistaus ostoskorissa & tilaussivuilla
// ------------------------------------------

@table-products-bottom-border:              #ddd;
@table-products-row-border:                 @hr-border;

.table-products {

	border-bottom: 1px solid @table-products-bottom-border;
	font-size: @font-size-base;

	@media (min-width: @screen-sm) {
		font-size: @font-size-small;
	}

	@media (min-width: @screen-md) {
		font-size: @font-size-base;
	}

	&.table td {
		border-color: @table-products-row-border;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&__col-right {
		position: relative !important;
	}

	&__image {
		margin-bottom: @grid-gutter-width/2;
		@media (min-width: @screen-sm) {
			margin-bottom: 0;
		}
		img {
			max-width: 200px;
			@media (min-width: @screen-sm) {
				max-width: 100%;
			}
		}
	}

	&__name {
		font-weight: 700;
		line-height: 1.3;
		margin-bottom: @grid-gutter-width/3;

		a {
			font-weight: 700;
			text-decoration: none !important;
		}
	}

	&__details {
		font-size: @font-size-small;
	}

	&__price,
	&__quantity {
		text-align: right;
		@media (min-width: @screen-sm) {
			text-align: center;
		}
		// @media (min-width: @screen-md) {
		// 	text-align: left;
		// }
	}

	&__price {
		font-size: @font-size-small;
		font-weight: 400;
	}

	&__actions {
		text-align: center;
		vertical-align: middle !important;
		.btn {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__row-total {
		font-weight: 700;
		position: absolute;
		bottom: @grid-gutter-width/2;
		right: 0;
		text-align: right;
		white-space: nowrap;
        
		@media (min-width: @screen-sm) {
			position: static;
			bottom: 0;
			right: 0;
		}
	}

	&__btn:extend(.btn, .btn-xs, .btn-link) {
		border: 0;
		padding-left: 0;
		padding-right: 0;

        &--delete {
            color: @text-color;
            font-size: 12px;
        }
	}

	tr:last-child {
		td {
			padding-bottom: @grid-gutter-width/2;
		}
	}

    &__delete {
        clear: both;
        text-align: center;
    }

    &__select {
        font-weight: 400;
    }
}
// (Footerin) yhteystiedot

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@contact-details-font-size:         @font-size-small;
@contact-details-name-font-weight:  700;

.contact-details {
    font-size: @contact-details-font-size;
    line-height: 1.3;

    &__name {
        font-weight: @contact-details-name-font-weight;
    }

    &__postal-address {
        margin-bottom: 4px;
    }

    &__tel-email {
        margin-bottom: 4px;
    }
}
.admin-box {

    background: #040404;
    color: #fff;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 700;
    opacity: 0.1;
    padding: 5px 10px;
    position: fixed;
    left: 10px;
    bottom: 10px;
    transition: all 0.3s ease;
    z-index: 10;

    &:hover {
        opacity: 1.0;
    }

    a {
        color: #fff;
        cursor: pointer;
        display: block;
        padding: 1px 0;

        &:hover,
        &:active,
        &:focus {
            color: #f2161f;
            text-decoration: none;
        }
    }

    &__queries {
        display: block;
        margin-top: 6px;
    }
    
}
// Bootstrap 4:n tyyliset margin- & padding-luokat
// -----------------------------------------------
// .m = margin
// .p = padding
// -a- = joka puolelta
// -x- = vas. & oik.
// -y- = ylh. & alh.
// -t- = top
// -r- = right
// -b- = bottom
// -l- = left
// -3 = 3rem
// -2 = 1.5rem
// -1 = 1rem
// -0 = 0

.m-a-3 { margin: (@spacer-y * 3) (@spacer-x * 3) !important; }
.m-a-2 { margin: (@spacer-y * 1.5) (@spacer-x * 1.5) !important; }
.m-a-1 { margin: @spacer-y @spacer-x !important; }
.m-a-0 { margin: 0 !important; }
.p-a-3 { padding: (@spacer-y * 3) (@spacer-x * 3) !important; }
.p-a-2 { padding: (@spacer-y * 1.5) (@spacer-x * 1.5) !important; }
.p-a-1 { padding: @spacer-y @spacer-x !important; }
.p-a-0 { padding: 0 !important; }

.m-x-3 {
    margin-left: @spacer-x * 3 !important;
    margin-right: @spacer-x * 3 !important;
}

.m-x-2 {
    margin-left: @spacer-x * 1.5 !important;
    margin-right: @spacer-x * 1.5 !important;
}

.m-x-1 {
    margin-left: @spacer-x !important;
    margin-right: @spacer-x !important;
}

.m-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.p-x-3 {
    padding-left: @spacer-x * 3 !important;
    padding-right: @spacer-x * 3 !important;
}

.p-x-2 {
    padding-left: @spacer-x * 1.5 !important;
    padding-right: @spacer-x * 1.5 !important;
}

.p-x-1 {
    padding-left: @spacer-x !important;
    padding-right: @spacer-x !important;
}

.p-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.m-y-3 {
    margin-top: @spacer-y * 3 !important;
    margin-bottom: @spacer-y * 3 !important;
}

.m-y-2 {
    margin-top: @spacer-y * 1.5 !important;
    margin-bottom: @spacer-y * 1.5 !important;
}

.m-y-1 {
    margin-top: @spacer-y !important;
    margin-bottom: @spacer-y !important;
}

.m-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.p-y-3 {
    padding-top: @spacer-y * 3 !important;
    padding-bottom: @spacer-y * 3 !important;
}

.p-y-2 {
    padding-top: @spacer-y * 1.5 !important;
    padding-bottom: @spacer-y * 1.5 !important;
}

.p-y-1 {
    padding-top: @spacer-y !important;
    padding-bottom: @spacer-y !important;
}

.p-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.m-t-3 { margin-top: @spacer-y * 3 !important; }
.m-t-2 { margin-top: @spacer-y * 1.5 !important; }
.m-t-1 { margin-top: @spacer-y !important; }
.m-t-0 { margin-top: 0 !important; }
.p-t-3 { padding-top: @spacer-y * 3 !important; }
.p-t-2 { padding-top: @spacer-y * 1.5 !important; }
.p-t-1 { padding-top: @spacer-y !important; }
.p-t-0 { padding-top: 0 !important; }

.m-b-3 { margin-bottom: @spacer-y * 3 !important; }
.m-b-2 { margin-bottom: @spacer-y * 1.5 !important; }
.m-b-1 { margin-bottom: @spacer-y !important; }
.m-b-0 { margin-bottom: 0 !important; }
.p-b-3 { padding-bottom: @spacer-y * 3 !important; }
.p-b-2 { padding-bottom: @spacer-y * 1.5 !important; }
.p-b-1 { padding-bottom: @spacer-y !important; }
.p-b-0 { padding-bottom: 0 !important; }

.m-l-3 { margin-left: @spacer-x * 3 !important; }
.m-l-2 { margin-left: @spacer-x * 1.5 !important; }
.m-l-1 { margin-left: @spacer-x !important; }
.m-l-0 { margin-left: 0 !important; }
.p-l-3 { padding-left: @spacer-x * 3 !important; }
.p-l-2 { padding-left: @spacer-x * 1.5 !important; }
.p-l-1 { padding-left: @spacer-x !important; }
.p-l-0 { padding-left: 0 !important; }

.m-r-3 { margin-right: @spacer-x * 3 !important; }
.m-r-2 { margin-right: @spacer-x * 1.5 !important; }
.m-r-1 { margin-right: @spacer-x !important; }
.m-r-0 { margin-right: 0 !important; }
.p-r-3 { padding-right: @spacer-x * 3 !important; }
.p-r-2 { padding-right: @spacer-x * 1.5 !important; }
.p-r-1 { padding-right: @spacer-x !important; }
.p-r-0 { padding-right: 0 !important; }


// Vanhoja apuluokkia. Saattavat olla vielä käytössä jossain.

.no-margin          { margin: 0; }
.no-margin-top      { margin-top: 0; }
.no-margin-bottom   { margin-bottom: 0; }
.no-padding         { padding: 0; }
.no-padding-top     { padding-top: 0; }
.no-padding-bottom  { padding-bottom: 0; }
.no-border          { border: 0; }
.no-border-top      { border-top: 0; }
.no-border-bottom   { border-bottom: 0; }


// Linkki, joka on oletuksena tekstin värinen.
// Hoverina näkyy varsinainen linkkiväri
.link-inverse {
    color: @text-color;

    &:hover {
        color: @link-color;
    }
}

// Elementti näkymään blockina mobiilikoossa.
.mobile-block {
    @media (max-width: @screen-sm-min) {
        display: block;
    }
}

// Font-weight-apuluokat Bootstrap 4 -tyyliin
.font-weight {
    &-normal    { font-weight: 400; }
    &-bold      { font-weight: 700; }
}

.valign {
    &-top       { vertical-align: top !important; }
    &-middle    { vertical-align: middle !important; }
    &-bottom    { vertical-align: bottom !important; }
}
//Pääsisältö = oikea palsta
// -------------------------

.content {

    .make-xs-column(12);

    &--full-width {
        .make-xs-column(12);
    }

    &--with-sidebar {
        .make-md-column(9);
        .make-md-column-push(3);
    }

    &--narrow {
        .make-xs-column(12);
        .make-sm-column(10);
        .make-sm-column-offset(1);
        .make-md-column(8);
        .make-md-column-offset(2);
    }

    &--extra-narrow {
        .make-ms-column(8);
        .make-ms-column-offset(2);
        .make-sm-column(8);
        .make-sm-column-offset(2);
        .make-md-column(6);
        .make-md-column-offset(3);
    }
}


//Sivulinkit & -sisällöt = vasen palsta
.sidebar {

    .make-xs-column(12);

    .make-md-column(3);
    .make-md-column-pull(9);

    margin-bottom: @grid-gutter-width*1.5;
    padding-top: @grid-gutter-width;

    @media (min-width: @screen-md) {
        padding-top: 0;
    }

}

.page-user_login .sidebar,
.page-user_register .sidebar,
.page-order_cancel .sidebar,
.page-order_checkout .sidebar,
.page-order_confirmation .sidebar,
.page-order_reject .sidebar,
.page-order_success .sidebar,
.content--full + .sidebar {
    display: none;
}
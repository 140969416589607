// Iso nosto-osio, kuten Kuvatapetissa
// -----------------------------------

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@spotlight-heading-font-size:           @font-size-h2;
@spotlight-heading-font-weight:         @headings-font-weight;

.spotlight {
    .row;

    &__image {
        .make-sm-column(10);
        .make-sm-column-offset(1);
        .make-md-column(7);
        .make-md-column-offset(0);
        .make-lg-column(8);
    }

    &__text {
        .make-sm-column(12);
        .make-md-column(5);
        .make-lg-column(4);
    }

    &__heading {
        font-size: @spotlight-heading-font-size;
        font-size: @spotlight-heading-font-weight;
        margin-top: 0;
    }

    &__button {
        .btn;
        .btn-primary;
        .btn-block;
        .btn-lg;
    }

    .slick-slider {
        margin-bottom: 0;
    }

    &__carousel {
        list-style: none;
        padding: 0;
    }
}
.banner {
    &--sidebar {

        img {
            display: block;
            margin: @grid-gutter-width auto;
            max-width: 280px;

            @media (min-width: @screen-md) {
                max-width: 100%;
            }
        }
    }
}
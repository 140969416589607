// Etusivun karuselli

// LESS-muuttujat, jotka voidaan jyrätä skin-variables.less-tiedostossa
@main-carousel-heading-font-size:       @font-size-h2;
@main-carousel-heading-font-weight:     @headings-font-weight;

.main-carousel {

    &__item {

        &:hover {
            .main-carousel__text-content {
                opacity: 1.0;
            }
        }

        &.slick-active {
            z-index: 10;

            .main-carousel__text-content {
                display: block;
            }
        }

        &.slick-cloned {
        }

    }

    &__text-content {

        background: rgba(0,0,0,0.5);
        color: #fff;
        display: none;
        opacity: 0;
        padding: @grid-gutter-width/2;
        position: absolute;
        bottom: 0;
        transition: all 0.3s ease;
        width: 100%;

        a {
            color: #fff;
            text-decoration: none !important;
        }

    }

    &__heading {
        color: #fff; // Jyrää mahdollisen erikseen määritellyn otsikkovärin
        font-size: @main-carousel-heading-font-size;
        font-weight: @main-carousel-heading-font-weight;
        margin: 0;
    }

    &__heading + &__caption {
        margin-top: @grid-gutter-width/3;
    }

    &__read-more {
        margin-top: @grid-gutter-width/3;
    }

    &__read-more-link {
        .btn;
        .btn-sm;
        .btn-primary;
    }

    &__image {
        width: 100%;
    }

}
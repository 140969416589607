@back-to-top-button-size:           36px;
@back-to-top-button-bg:             #000;
@back-to-top-button-color:          @gray-light;
@back-to-top-button-opacity:        1.0;
@back-to-top-button-hover-color:    @back-to-top-button-color;
@back-to-top-button-hover-opacity:  0.7;

.back-to-top {
    color: @back-to-top-button-color;
    position: fixed;
    bottom: @grid-gutter-width / 2;
    right: @grid-gutter-width / 2;
    text-decoration: none;
    background-color: @back-to-top-button-bg;
    display: none;
    opacity: @back-to-top-button-opacity;
    transition: all @transition-duration-default ease;

    &:hover {
        color: @back-to-top-button-hover-color;
        opacity: @back-to-top-button-hover-opacity;
        transition: all @transition-duration-hover ease;
    }

    &:active,
    &:focus {
        color: @back-to-top-button-hover-color;
        outline: none;
    }

    &__icon {
        .square(@back-to-top-button-size);
        font-size: @back-to-top-button-size / 2;
        line-height: @back-to-top-button-size;
        text-align: center;
        position: relative;
        top: -1px; // Käytettävä FA-ikoni makaa hieman keskikohdan alapuolella
    }
}
// Tilauksen vahvistusvaiheen yhteensä-osio
// ----------------------------------------

.order-total {

    font-size: @font-size-base;
    margin-bottom: @grid-gutter-width;

    &__total {
        font-weight: 700;
    }

    &__right {
        float: right;
        margin-left: @grid-gutter-width/3;
        text-align: right;
    }

    &__payment-line {
        font-size: @font-size-h4;
        font-weight: 700;
        margin-bottom: 2px;
    }

}